import React, {useState, Fragment, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {checkBlastCounter} from 'services/analytics';
import {
  Grid,
  Divider,
  Paper,
  Container,
  useMediaQuery,
  Button,
  Avatar,
} from '@material-ui/core';
import Slider from 'react-slick';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const packages = [
  {
    title: '1 Bulan',
    priceNett: '320rb',
    term: 'bln',
  },
  {
    title: '1 Tahun',
    priceNett: '2,5jt',
    term: 'thn',
  },
  {
    title: '2 Tahun',
    priceNett: '4,5jt',
    term: '2 thn',
    isPopular: true,
  },
];

const HomePage = () => {
  const history = useHistory();
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E0"
          />
        </svg>
      </div>
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('c');
    if (code)
      checkBlastCounter({code}).then((res) => {
        if (res.isSuccess) {
          console.log(code + ' berhasil di input');
        }
      });
  }, []);
  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-6"
        style={{backgroundColor: '#1166D8'}}>
        <Helmet>
          <title>Software Apotek Terbaik</title>
          <meta
            name="description"
            content="Software apotek berbasis cloud yang mempermudah pengelola ataupun pemilik apotek dalam mengatur dan memonitor kinerja apotek hingga melakukan aktifitas-aktifitas rutin di apotek. Sekarang, pantau apotek bisa dilakukan dimanapun dan kapanpun. Satu Aplikasi untuk kelola semua aktivitas di apotekmu! Yuk #GoDigital bersama Apotek Digital!"
          />
          <meta
            name="og:description"
            content="Software apotek berbasis cloud yang mempermudah pengelola ataupun pemilik apotek dalam mengatur dan memonitor kinerja apotek hingga melakukan aktifitas-aktifitas rutin di apotek. Sekarang, pantau apotek bisa dilakukan dimanapun dan kapanpun. Satu Aplikasi untuk kelola semua aktivitas di apotekmu! Yuk #GoDigital bersama Apotek Digital!"
          />
        </Helmet>
        {renderWave()}
        <div className="container">
          <div className="row py-5">
            <div className="col-12 py-5 text-center order-2 order-md-1">
              <h1 className=" text-white font-weight-bold">
                Software Apotek Anti Ribet
              </h1>
              <br />
              <h4 className=" text-white font-weight-bold">
                Buat Bisnis Apotek Makin Untung
              </h4>
              <br />
              <h6 className=" text-white font-weight-bold">
                Satu Aplikasi Apotek untuk kelola semua aktivitas di
                apotekmu! Yuk #GoDigital bersama Apotek Digital!
              </h6>
            </div>
            <div className="col-sm-12 text-center order-2 order-md-2 my-2">
              <button
                onClick={() => {
                  history.push('/register');
                }}
                className="btn btn-green">
                Coba Gratis 14 Hari
              </button>
            </div>
            <div className="col-sm-12 text-center order-2 order-md-2 my-2">
              <a
                target="_blank"
                href="https://wa.me/6285223932189?text=Halo,%20saya%20ingin%20agendakan%20demo%20Apotek%20Digital"
                className="btn btn-warning">
                Agendakan Demo Gratis
              </a>
            </div>
            <div className="col-12 text-center order-1 order-md-2">
              <img
                src="/images/laptop2.png"
                style={{maxWidth: '100%', width: 500}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const products = [
    {
      title: 'Mudah Digunakan',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/mudah-digunakan_m9mwu7.png',
      desc:
        'Apotek Digital di-desain agar dapat digunakan dengan mudah, baik untuk pengguna yang familiar teknologi ataupun tidak.',
    },
    {
      title: 'Fitur Lengkap',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/fitur-lengkap_jlgelt.png',
      desc:
        'Fitur yang lengkap di Apotek Digital siap membantu pengusaha apotek untuk lebih fokus kepada hal-hal strategis.',
    },
    {
      title: 'Harga Terjangkau',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/hemat_ve1cmy.png',
      desc:
        'Kami sangat paham merintis bisnis Apotek tidaklah murah, dengan harga terjangkau, kami siap untuk tumbuh bersama',
    },
    {
      title: 'Multi Platform',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/multi-platform_cglizb.png',
      desc:
        'Tidak perlu repot mencari device yang cocok, karena Apotek Digital dapat diakses di sistem operasi apapun di gadget Anda.',
    },
  ];
  const productSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          centerPadding: '50px',
          slidesToScroll: 2,
        },
      },
    ],
  };
  const pricesSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // className: 'center',
          // centerMode: true,
          slidesToShow: 1.5,
          centerPadding: '50px',
          slidesToScroll: 1,
        },
      },
    ],
  };
  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  );
  const renderValues2 = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD'}}>
        <Container
          style={{
            ...{marginTop: -80, width: '100%'},
            ...(matches ? {} : {padding: 0}),
          }}>
          <br />
          <Slider {...productSliderSettings}>
            {(products || []).map((item, index) => (
              <Grid
                container
                justify="center"
                key={index}
                spacing={matches ? 5 : 1}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{textAlign: 'center'}}>
                  <Paper
                    elevation={4}
                    style={{
                      padding: 16,
                      borderRadius: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      marginLeft: matches ? 0 : 10,
                      height: '100%',
                      textAlign: 'center',
                    }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <img
                        src={item.image}
                        style={{
                          height: 80,
                          marginBottom: 40,
                          marginTop: 30,
                        }}
                      />
                      <h5>{item.title}</h5>
                      <p style={{color: '#8B9BB1'}}>{item.desc}</p>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Slider>
        </Container>
      </div>
    );
  };

  const testimoniSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const testinomiContent = [
    {
      name: 'Apt. Safira',
      role: 'Apotek Nuril, Kalimantan Selatan',
      content: `Apotek Digital sangat membantu dalam manajemen Apotek. Mulai dari manajemen stok untuk mencegah kekosongan barang dan manajemen keuangan untuk mencegah adanya kerugian.
      Apotek Digital juga bisa melihat omset, profit, dan jumlah pasien perharinya.
      
      selain itu, Apotek Digital sangat praktis digunakan kapanpun dan dimanapun berada.
      
      Dengan banyaknya kelebihan dari Apotek Digital, maka tidak perlu pusing dengan manajemen apotek dan bisa lebih fokus dalam pelayanan terhadap pasien.
      `,
      imageUrl: '/images/testimoni/apotek-nuril.jpg',
    },
    {
      name: 'Apt. Fitria Iriani',
      role: 'Apotek Yunas Farma',
      content: `Setelah menggunakan Apotek Digital, managemen keuangan apotek kami jadi lebih bagus. Awalnya mau pakai kartu stok manual, tapi karena Apotek Digital dilengkapi fitur itu jadi agak mengurangi keribetan apotek kami. Dengan Apotek Digital bisa meriksa stok obat tiap hari. :D pengadaan obat jadi lebih mudah. Sistemnya mudah dipahami, jadi dijelaskan seklai oleh admin bisa langsung paham. Sukses terus Apotek Digital. Recommended banget pokoknya untuk apotek yang baru merintis`,
      imageUrl: '/images/testimoni/apotek-yunas-farma.jpg',
    },
    {
      name: 'Muhammad Kholiq Hidayat',
      role: 'Apotek Nusantara',
      content:
        'Makasih Apotek Digital, terbantu banget dengan sistemnya yang mudah dipahami, harganya terjangkau, dan udah ga ribet lagi menggunakan kartu stok manual karena di Apotek Digital dilengkapi dengan banyak fitur yang memudahkan dalam memantau stock obat defecta, stok ED bisa terpantau dengan baik. Recommended banget deh pokoknya untuk apotek2 yang butuh kemudahan dalam mengelola apoteknya',
      imageUrl: '/images/testimoni/apotek-nusantara.jpg',
    },
    {
      name: 'Apoteker Zaini',
      role: 'Apotek 1000 Sehat',
      content: `1 Tahun yang Lalu Kami Mulai Berbisnis Apotek yang diberi Nama Apotek 1000 Sehat. Berawal dari 3 Apoteker Pendiri dan Modal Minim, Rasa Pesimis dan Optimis menjadi Campur Aduk selama Menjalani Bisnis ini.

        Gak Kebayang!!! Awalnya Jumlah Item Obatnya cuma 100-200 Item dan sekarang sudah 1000an Item Obat di tambah Harus Jeli menjaga CashFlow agar Tetap SEHAT Keuangan dan Stok Obat. Tetapi Semua itu Menjadi MUDAH semenjak Menggunakan Aplikasi Apotek Digital.
        
        Sekarang, kami TIDAK TAKUT lagi Kehabisan Stok Obat, Membaca CASH FLOW keuangan, Monitor Omzet & Profit Harian, Semua Manajemen Apotek dibantu sama Apotek Digital. Dimanapun dan Kapanpun bisa di Akses lewat Laptop/PC maupun Handphone kita, Serasa Bisnis Apotek kita dalam Genggaman. Terima Kasih Apotek Digital 😀`,
      imageUrl: '/images/testimoni/apotek-1000sehat.jpg',
    },
    {
      name: 'Apotek Kaffah Farma Padang',
      role: '',
      content: `Terimakasih apotek digital, sangat membantu saya dalam operasional apotek mulai dari manajemen stock, keuangan, hingga sistem kasirnya. Frontliner nya juga sigap membantu.`,
      imageUrl: '',
    },
    {
      name: 'Fajar L Faisal',
      role: 'Apotek Bayu Endah',
      content: `Apotek digital sangat membantu mengontrol jalannya apotek sehingga mempermudah kita buat mengontrol pengelolaan apotek jadi lebih mudah, ga  pusing lagi masalah pembukuan dan pengadaan barang apotek. Terimakasih banyak Apotek Digital semoga terus menjadi yang terbaik. Gaaaskeun..`,
      imageUrl: '/images/testimoni/apotek-bayuendah.jpg',
    },
    {
      name: 'Pak Habibie',
      role: 'Apotek Medisina',
      content: `Aplikasi apotik digital sangat simple dan mudah.. kami pihak apotek medisina Banda Aceh sangat senang memakai aplikasi ini karena semua management transaksi di apotek menjadi lebih jelas. Management apotek terlaksana tanpa ribet`,
      imageUrl: '/images/testimoni/apotek-medisina.jpg',
    },
    {
      name: 'Dita Harta Purwanti',
      role: 'Danta Medika',
      content: `Smnjak menggunakan Apotek Digital ngurus apotek jadi lebih mudah.. aplikasiny beneran bagus.. mempermudah kerja.. bisa dibuka di rumah, di jalan, dimana2😂. Aplikasiny jg gak ribet.. pokoknya mantap`,
      imageUrl: '/images/testimoni/apotek-dantamedika.jpg',
    },
  ];
  const renderTestimoni = () => {
    return (
      <div
        style={{
          backgroundColor: '#FAFAFD',
          marginBottom: 100,
          maxWidth: '100%',
        }}>
        <Container
          style={{
            ...{marginTop: -80},
            ...(matches ? {} : {padding: 0}),
          }}>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgb(250, 250, 253)',
            }}>
            <br />
            <br />
            <Divider
              style={{
                backgroundColor: 'rgb(17, 102, 216)',
                width: '80%',
              }}
            />
            <br />
            <br />
          </Grid>
          <Grid>
            <div style={{textAlign: 'center'}}>
              <div>Telah digunakan oleh:</div>
              <br />
              <h1>300++</h1>
              <br />
              <h5>Apotek, Klinik, Toko Obat, Toko Alkes </h5>
            </div>
          </Grid>
          <br />
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgb(250, 250, 253)',
            }}>
            <br />
            <br />
            <Divider
              style={{
                backgroundColor: 'rgb(17, 102, 216)',
                width: '80%',
              }}
            />

            <br />
            <br />
          </Grid>
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <div className="sub-title">TESTIMONI PENGGUNA</div>
              <h3>Apa Kata Mereka?</h3>
            </div>
          </div>
          <br />
          <Slider {...testimoniSliderSettings}>
            {(testinomiContent || []).map((item, index) => (
              <Grid container justify="flex-start" key={index}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{textAlign: 'left'}}>
                  {
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: 10,
                      }}>
                      {item.imageUrl ? (
                        <img
                          src={item.imageUrl}
                          style={{
                            height: 100,
                            marginBottom: 40,
                            marginTop: 30,
                          }}
                        />
                      ) : (
                        <Avatar
                          style={{
                            height: 100,
                            width: 100,
                            marginBottom: 40,
                            marginTop: 30,
                          }}>
                          <AccountCircleIcon
                            style={{
                              height: 100,
                              width: 100,
                              marginBottom: 40,
                              marginTop: 30,
                            }}
                          />
                        </Avatar>
                      )}
                      <p>{`"${item.content}"`}</p>
                      <h5>{item.name}</h5>
                      <p style={{color: '#8B9BB1'}}>{item.role}</p>
                    </div>
                  }
                </Grid>
              </Grid>
            ))}
          </Slider>
        </Container>
      </div>
    );
  };

  const renderAboutUs = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD'}} className="py-6">
        <div className="container">
          <div className="row text-md-left text-center">
            <div className="col-md-5 mt-5">
              <img
                src="https://res.cloudinary.com/apotekdigital/image/upload/v1573202104/web/illustrasi_home_vm9qw0.png"
                style={{maxWidth: '90%'}}
              />
            </div>
            <div className="col-md-7 mb-5 mt-5">
              <div className="sub-title">MISI KAMI</div>
              <h2>Transformasi Bisnis Apotek di Indonesia</h2>
              <p style={{color: '#8B9BB1'}}>
                Apotek Digital lahir untuk mentransformasi bisnis
                apotek naik level di Indonesia. Dengan pemanfaatan
                teknologi yang disesuaikan dengan kebutuhan di
                lapangan, Apotek Digital akan terus berinovasi untuk
                membantu apoteker dan PSA merancang masa depan bisnis,
                pengelolaan dan pelayanan ke arah yang lebih baik.
              </p>
              <p style={{color: '#8B9BB1'}}>
                Kelola Bisnis apotek sekarang jadi lebih mudah bersama
                Apotek Digital!
              </p>
              <p style={{fontWeight: 'bold'}}>
                Kamu pengusaha apotek? yuk #MajuBersama ApotekDigital
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFirstClosing = () => {
    return (
      <div style={{backgroundColor: '#1166D8', position: 'relative'}}>
        {renderWave()}

        <div className="container">
          <div className="row">
            <div className="col-md-6 text-md-left text-center py-6">
              <div className="sub-title">AYO, TUNGGU APA LAGI?</div>
              <h2 className="text-white">
                Semua Aktivitas Bisnis Apotek Dapat Dilakukan di Sini!
              </h2>
              <p className="text-white" style={{opacity: 0.8}}></p>
              <Link to="/register" className="btn btn-green">
                Daftar Segera
              </Link>
            </div>
            <div className="col-md-6 text-center">
              <img
                src="https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_500/v1631284370/web/hand-apps_phyi9d.png"
                style={{
                  maxWidth: '500px',
                  width: '90%',
                  position: matches ? 'absolute' : 'relative',
                  bottom: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [activeFeature, setActiveFeature] = useState(0);
  const features = [
    {
      title: 'Akses Dimanapun & Kapanpun',
      icon:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_50/v1573202424/web/Logo_Pelatihan_Bisnis_Apotek_suokaw.png',
      imageUrl:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_500/v1631235131/web/dashboard_lemr5f.png',
      desc: 'Ingin memantau Apotek? Tidak harus ke Apotek kok!',
    },
    {
      title: 'Laporan Keuangan Otomatis',
      icon:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_50/v1573203868/web/icon_1-min_w9k1tn.png',
      imageUrl:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_500/v1631197182/web/5-laporan-otomatis_oapmgh.png',
      desc: 'Semua laporan Apotek Digital yang buatkan, Otomatis!',
    },
    {
      title: 'Rekomendasi Pengadaan',
      icon:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_50/v1573203868/web/icon_2-min_dmivin.png',
      imageUrl:
        'https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_500/v1631197182/web/4-analisis-pengadaan_euaooc.png',
      desc: 'Lakukan pembelian dengan data. Dijamin efisien!',
    },
  ];
  const renderFeatures = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD'}} className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <div className="sub-title">FITUR</div>
              <h3>Fitur Unggulan dari Apotek Digital</h3>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <img
                src={_.get(
                  features,
                  `[${activeFeature}].imageUrl`,
                  '',
                )}
                style={{maxWidth: '100%'}}
              />
            </div>
            <div className="col-md-6 my-1">
              {features.map((item, index) => (
                <Button
                  variant={
                    activeFeature === index ? 'contained' : 'outlined'
                  }
                  key={index}
                  color="primary"
                  onClick={() => setActiveFeature(index)}
                  style={{
                    padding: 10,
                    marginTop: 10,
                    borderRadius: 16,
                    width: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      filter:
                        activeFeature === index
                          ? `brightness(0) invert(1)`
                          : 'none',
                    }}>
                    <img
                      style={{
                        height: 50,
                        marginRight: 10,
                      }}
                      src={item.icon}
                    />
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textTransform: 'none',
                      }}>
                      <h6 style={!matches ? {fontSize: 12} : {}}>
                        {item.title}
                      </h6>
                      <p
                        style={{
                          ...(!matches ? {fontSize: 12} : {}),
                          color: '#3339',
                          marginBottom: 0,
                        }}>
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </Button>
              ))}
              <Button
                onClick={() => history.push('/features')}
                endIcon={<NavigateNextIcon />}
                style={{
                  padding: 10,
                  margin: 10,
                  borderRadius: 16,
                  width: '100%',
                  justifyContent: 'center',
                }}>
                Fitur Lainnya
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPricing = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgb(17, 102, 216)',
          paddingBottom: 50,
        }}>
        <div style={{}}>
          <Container
            style={{
              ...{
                marginTop: 0,
                paddingTop: 100,
                width: '100%',
              },
            }}>
            <Grid className="text-center">
              <h2 style={{color: 'white'}}>Paket Berlangganan</h2>
            </Grid>
            <br />
            <br />
            <br />
            <Slider {...pricesSliderSettings}>
              {packages.map((product, index) => (
                <Grid
                  item
                  className="pa-1"
                  style={{width: '100%'}}
                  key={product.term}>
                  <Paper
                    style={{
                      padding: 16,
                      margin: 10,
                      textAlign: 'center',
                      borderRadius: 10,
                    }}>
                    <h3>{product.title}</h3>
                    <p style={{color: '#8B9BB1'}}>
                      Akses Semua Fitur
                    </p>
                    <div style={{height: 30}}>
                      {product.isPopular && (
                        <span className="badge badge-success">
                          MOST POPULAR
                        </span>
                      )}
                    </div>
                    <h5
                      style={{
                        textDecoration: 'line-through',
                        marginTop: 30,
                        color: '#AAA',
                      }}>
                      {product.price}
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 10,
                      }}>
                      <div>
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: '#AAA',
                          }}>
                          Rp
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          marginBottom: 20,
                        }}>
                        <h1
                          className="text-default"
                          style={{
                            marginBottom: 0,
                            fontWeight: 1000,
                            color: '#09A599',
                          }}>
                          {product.priceNett}
                        </h1>
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: '#AAA',
                          }}>
                          /{product.term}
                        </div>
                      </div>
                    </div>
                    <Link
                      to="/register"
                      className="btn btn-default btn-sm ">
                      Coba Gratis 14 Hari
                    </Link>
                  </Paper>
                </Grid>
              ))}
            </Slider>
            <Grid style={{textAlign: 'center'}}>
              <h5
                style={{
                  color: 'white',

                  marginBottom: 20,
                  marginTop: 30,
                }}>
                Ingin tanya-tanya dan ngobrol lebih lanjut ke tim
                Apotek Digital? yuk agendakan meeting/demo gratis
                dengan tim kami!{' '}
              </h5>
              <a
                target="_blank"
                href="https://wa.me/6285223932189?text=Halo,%20saya%20ingin%20agendakan%20demo%20Apotek%20Digital"
                className="btn btn-warning">
                Agendakan Demo Gratis
              </a>
            </Grid>
          </Container>
        </div>
      </div>
    );
  };
  const renderSecondClosing = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD', position: 'relative'}}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '50%',
            bottom: 0,
            backgroundColor: '#1166D8',
          }}
        />
        <div className="container">
          <div className="card px-5">
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h2>
                  Saatnya investasi pada teknologi untuk meningkatkan
                  bisnis apotekmu!
                </h2>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja gratis!
                </p>
                <Link to="/register" className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(250, 250, 253)',
        maxWidth: '100%',
        overflow: 'hidden',
      }}>
      {renderTopContent()}
      {renderValues2()}
      {renderAboutUs()}
      {renderFirstClosing()}
      {renderFeatures()}

      {renderTestimoni()}
      {/* {renderRegistrationSteps()} */}
      {renderSecondClosing()}
      {renderPricing()}
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'rgb(17, 102, 216)',
          paddingTop: 20,
        }}>
        <Divider
          style={{
            backgroundColor: 'white',
            width: '80%',
          }}
        />
      </Grid>
      {/*this.renderModalPromo()*/}
    </div>
  );
};

export default HomePage;
