import React from 'react';

const Loader = () => {
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E0"
          />
        </svg>
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 30,
        backgroundColor: '#1166D8',
        color: 'white',
        padding: 20,
        textAlign: 'center',
        position: 'relative',
      }}>
      <div>
        {/* <i
          className="fa fa-spinner fa-spin text-white mb-3"
          aria-hidden="true"></i> */}
        <div
          className="animated-loader"
          style={{
            zIndex: 9999,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 100,
            margin: 'auto',
          }}></div>
        <div className="my-3 text-center">
          <img
            src="/images/logo_white_small.png"
            width="100"
            className="blink-image"
          />
        </div>
        <div>
          <div style={{fontSize: 13}}>Loading..</div>
        </div>
      </div>
      {renderWave()}
    </div>
  );
};
export default Loader;
