export const functionUrl = process.env.REACT_APP_FUNCTION_URL;
export const searchUrl = process.env.REACT_APP_SEARCH_URL;
export const homeUrl =
  window.location.origin || process.env.REACT_APP_HOME_URL;
export const isLocalhost = () => {
  // return false; //kalau mau coba yang di deploy
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
};
