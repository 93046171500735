import React, {Fragment} from 'react';
import {Link, useParams} from 'react-router-dom';

const TermsAndConditions = () => {
  const lastUpdatedAt = '1 Desember 2021';
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E022"
          />
        </svg>
      </div>
    );
  };

  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-5"
        style={{backgroundColor: 'aliceblue'}}>
        {renderWave()}
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-12 col-sm-12 py-5 text-md-left text-center order-2 order-md-1">
              <h1 className=" font-weight-bold text-center">
                Kebijakan Privasi
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSecondClosing = () => {
    return (
      <div
        style={{
          backgroundColor: '#FAFAFD',
          position: 'relative',
          paddingTop: 30,
        }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '50%',
            bottom: 0,
            backgroundColor: 'aliceblue',
          }}
        />
        <div className="container">
          <div className="card px-5">
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h2>
                  Saatnya investasi pada teknologi untuk meningkatkan
                  bisnis apotekmu!
                </h2>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja dengan paket gratis hingga
                  kapanpun!
                </p>
                <Link to="/register" className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderTopContent()}
      <div
        style={{
          backgroundColor: '#FAFAFD',
        }}>
        <div
          className="container pt-5"
          style={{minHeight: 300, maxWidth: 800}}>
          <div style={{position: 'relative'}}>
            <br />
            <p>
              PT Teknologi Kesehatan Indonesia(selanjutnya disebut “
              <b>Apotek Digital</b>” atau “<b>Kami</b>”) memiliki
              komitmen tinggi terhadap perlindungan data Anda. Kami
              bertanggung jawab untuk menegakkan prinsip-prinsip
              pengurusan data dengan integritas yang penuh, dimana
              Kami selalu menjadikan keamanan data Anda menjadi
              prioritas utama Kami. Untuk itu, keamanan data Anda
              (serta data lainnya yang Anda percayakan kepada Kami)
              adalah hal yang terpenting untuk Kami.
            </p>
            <p>
              Kebijakan privasi ini (selanjutnya disebut “
              <b>Kebijakan</b>”) mengatur tentang cara Kami
              mengumpulkan dan menggunakan data Anda dalam
              https://apotekdigital.com (selanjutnya disebut “
              <b>Situs</b>
              ”) dan/atau <i>software</i> apotek <i>online</i> yang
              Kami kembangkan di https://app.apotekdigital.com
              (selanjutnya disebut “<b>Produk</b>”). Dengan
              menggunakan Produk Apotek Digital dan mengakses Situs
              Kami, Anda telah menyetujui dan terikat dengan hal-hal
              yang telah terdapat dalam Kebijakan ini. Oleh karena
              itu, Kami berharap Anda membaca Kebijakan ini untuk
              memastikan bahwa Anda mengerti bagaimana cara Kami
              menangani data Anda.
            </p>
            <div class="about-list" id="privacy-one">
              <h4>Cara mengumpulkan data Anda</h4>
              <p>
                Apotek Digital adalah penyedia layanan <i>software</i>{' '}
                manajemen apotek <i>cloud based</i> yang handal untuk
                usaha kecil dan menengah khususnya usaha ritel bidang
                produk kesehatan di Indonesia. Ketika Anda menggunakan
                Produk atau mengakses Situs, Anda secara langsung
                maupun tidak langsung memberikan data Anda kepada Kami
                yang akan Kami lindungi dan gunakan dalam metode yang
                diatur dalam Kebijakan ini. Sebagai contoh, data yang
                kami kumpulkan dapat berupa nama, alamat <i>e-mail</i>
                , nomor telepon dan data lainnya yang Anda berikan
                sehubungan dengan penggunaan Anda atas Produk dan
                akses Situs. Kami dapat mengumpulkan:
              </p>
              <ul>
                <li>
                  <i>Email</i> dan <i>Password</i> Anda untuk
                  memberikan akses ke Produk Apotek Digital;
                </li>
                <li>
                  Data yang Anda masukkan ketika menggunakan Produk
                  Apotek Digital;
                </li>
                <li>
                  Informasi tentang perangkat Anda ketika mengakses
                  Situs atau Produk, seperti <i>IP Address</i> dan
                  informasi <i>browser</i>;
                </li>
                <li>
                  Kesan, pesan, kritik, dan saran dari Anda,
                  pembicaraan melalui <i>chat</i> dan interaksi
                  lainnya di Situs atau Produk Kami; dan/atau
                </li>
                <li>
                  Seluruh hal yang Anda lakukan dan data yang Anda
                  masukkan ke dalam Situs atau Produk.
                </li>
              </ul>
              <p>
                Kami dapat mengumpulkan data secara langsung dari Anda
                ketika Anda:
              </p>
              <ul>
                <li>Mengakses Situs;</li>
                <li>
                  Mendaftar untuk menggunakan Produk Apotek Digital;
                </li>
                <li>Menggunakan Produk Apotek Digital;</li>
                <li>
                  Mengirimkan kesan, pesan, kritik dan saran Anda;
                </li>
                <li>
                  Menghubungi tim <i>support</i> Kami; atau
                </li>
                <li>
                  Memasukkan informasi dalam bentuk apapun dalam Situs
                  atau Product.
                </li>
              </ul>
              <p>
                Data yang Anda masukkan adalah data Anda sendiri yang
                Anda anggap benar, dan Anda bertanggung jawab penuh
                atas segala data yang Anda masukkan dalam Situs atau
                Produk Kami.
              </p>
            </div>
            <div class="about-list" id="privacy-two">
              <h4>Contoh penggunaan data Anda</h4>
              <p>
                Setiap data yang Anda masukkan ke dalam Situs dan/atau
                Produk dapat kami gunakan, termasuk namun tidak
                terbatas untuk:
              </p>
              <ul>
                <li>
                  Memproses data Anda, termasuk namun tidak terbatas
                  pada laporan keuangan, pengajuan perpajakan, faktur
                  penjualan atau pembelian, laporan usaha, dan
                  lainnya;
                </li>
                <li>
                  Mengantar surat dan barang yang Anda beli dari kami;
                </li>
                <li>
                  Menginformasikan pengembangan Produk, penawaran
                  penjualan dan perawatan atau gangguan apa pun yang
                  mungkin Anda hadapi;
                </li>
                <li>Melakukan proses pembayaran; dan</li>{' '}
                <li>
                  Mengetahui informasi kunjungan Anda, seperti waktu
                  kunjungan dan apa yang Anda lakukan dalam kunjungan
                  tersebut.
                </li>
              </ul>
              <p>
                Kami juga dapat mengumpulkan dan menggunakan data Anda
                yang tidak dapat diidentifikasi sebagai pribadi,
                untuk:
              </p>
              <ul>
                <li>
                  Mengoptimalkan Produk, Situs, kampanye pemasaran,
                  dan lainnya;
                </li>
                <li>
                  Membantu Kami untuk mengetahui bagaimana cara Anda
                  menggunakan Produk;
                </li>
                <li>Melihat efektifitas Situs dan Produk;</li>
                <li>
                  Menemukan dan menyelesaikan masalah yang ada di
                  Situs dan Produk; dan
                </li>
                <li>Mengembangkan Product.</li>
              </ul>
            </div>
            <div class="about-list" id="privacy-three">
              <h4>Pembagian data ke pihak ketiga</h4>
              <p>
                Kami tidak akan menjual, menyewakan, atau menyebarkan
                data Anda tanpa izin dari Anda kecuali ditentukan lain
                dalam Kebijakan ini. Kami hanya akan mengungkapkan
                data Anda kepada pihak ketiga atas izin Anda atau jika
                diperlukan untuk mengakomodir kebutuhan penggunaan
                Produk Anda.
              </p>
              <p>
                Dengan memberikan izin apapun untuk mengungkapkan data
                Anda, Anda mengizinkan Kami untuk mengungkapkan dan
                mengirimkan data tersebut ke pihak ketiga terpilih,
                dan Anda membebaskan Kami atas segala kerugian atau
                kerusakan yang timbul dari kegagalan pihak ketiga
                untuk mengamankan data Anda. Kami tidak dapat menjamin
                keamanan data Anda pada sistem pihak ketiga dan Kami
                tidak memiliki kontrol atau tanggung jawab atas
                kebijakan privasi atau konten dari aplikasi pihak
                ketiga. Anda memiliki tanggung jawab untuk memeriksa
                kebijakan privasi dari setiap pihak ketiga, sehingga
                Anda dapat memahami bagaimana mereka akan menangani
                data Anda.
              </p>
              <p>
                Kami mungkin mengungkapkan data Anda tanpa persetujuan
                Anda untuk mematuhi perintah pengadilan atau keputusan
                badan pemerintah yang sah atau peraturan yang berlaku.
                Kemudian apabila memungkinkan dan diperbolehkan, Kami
                akan memberi tahu Anda apabila Kami mengungkapkan data
                Anda.
              </p>
            </div>
            <div class="about-list" id="privacy-four">
              <h4>Penggunaan Cookies</h4>
              <p>
                <i>Cookies</i>&nbsp;merupakan berkas teks yang
                ditempatkan di komputer Anda untuk kepentingan
                pencatatan. Kami menggunakan <i>cookies</i> untuk
                membantu Kami memberikan Anda kemudahan dalam
                menggunakan Produk.&nbsp;<i>Cookies</i>&nbsp;tidak
                dapat digunakan untuk menjalankan program atau
                mengirim virus ke komputer Anda.
              </p>
              <p>
                <i>Cookies</i> akan mencatat data Anda seperti data
                yang Anda simpan ke Situs atau Produk. Sebagai contoh,
                jika Anda membuat akun di dalam Situs, <i>cookies</i>
                akan membantu Kami untuk mengingat data yang spesifik
                untuk kunjungan Anda berikutnya. Ketika Anda kembali
                ke dalam Situs, data yang Anda berikan sebelumnya akan
                diberikan kembali, sehingga akan menjadi lebih mudah
                apabila Anda mengaktifkan <i>cookies</i> Anda.
              </p>
              <p>
                Sebagai tambahan, pihak lainnya juga dapat menganalisa
                data untuk Kami, seperti Google Analytics. Mereka
                dapat mengumpulkan data Anda yang tidak dapat
                diidentifikasi sebagai pribadi tentang aktivitas
                online Anda saat Anda menggunakan Produk Kami atau
                mengakses Situs.
              </p>
            </div>
            <div class="about-list" id="privacy-five">
              <h4>Keamanan Data</h4>
              <p>
                Kami juga selalu berusaha meningkatkan keamanan data
                Anda dengan menggunakan teknologi terbaru dan mematuhi
                peraturan yang berlaku untuk memenuhi harapan pihak
                terkait, dan untuk memastikan peningkatan kualitas
                yang berkelanjutan.
              </p>
            </div>
            <div class="about-list" id="privacy-six">
              <h4>Kontak</h4>
              <p>
                Apabila Anda ingin mengajukan keluhan tentang data
                Anda atau Anda memiliki pertanyaan lain mengenai
                privasi atau keamanan data Anda silahkan menghubungi
                Kami melalui:
              </p>
              <ul>
                <li>
                  <i>E-mail</i> :{' '}
                  <a href="mailto:support@apotekdigital.com">
                    support@apotekdigital.com
                  </a>
                </li>{' '}
              </ul>
            </div>
            <div class="about-list" id="privacy-seven">
              <h4>Perubahan terhadap Kebijakan Privasi</h4>
              <p>
                Kami memiliki hak untuk mengubah Kebijakan ini
                sewaktu-waktu yang akan berlaku efektif ketika telah
                diunggah ke Situs. Pembaharuan Kebijakan dapat
                mempengaruhi penggunaan Anda atas Produk atau Situs
                atau keamanan data Anda. Kami akan melakukan
                pemberitahuan kepada Anda mengenai perubahan yang
                telah Kami lakukan (jika diperlukan), dengan cara dan
                rentang waktu yang wajar. Apabila Anda tetap
                melanjutkan penggunaan Produk, maka hal tersebut akan
                dianggap sebagai persetujuan terhadap segala perubahan
                Kebijakan.
              </p>
            </div>
          </div>
        </div>
      </div>
      {renderSecondClosing()}
    </Fragment>
  );
};

export default TermsAndConditions;
