import React, {useState, Fragment, useEffect, useRef} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';
import axios from 'axios';
import {checkBlastCounter} from 'services/analytics';
import {
  Grid,
  Divider,
  Paper,
  Container,
  useMediaQuery,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import Slider from 'react-slick';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import _ from 'lodash';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {Helmet} from 'react-helmet';

const allFeatures = [
  {
    title: 'Penjualan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/penjualan_bolzfl.png',
    desc:
      'Semua aktivitas penjualan dapat tercatat dengan baik, selain itu juga dilengkapi dengan fitur laporan dan analisis.',
    childs: [
      {
        title: 'Kasir',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631197182/web/1-pencatatan-digital_w4mqwg.png',
        desc:
          'Fitur kasir adalah dimana Anda dapat menginput transaksi penjualan untuk pelangggan Anda. Di fitur ini, Anda dapat menambahkan produk-produk, memasukkan jumlahnya, serta mengeset harga jual untuk tiap produknya. Selain itu juga dapat memilih pelanggan ataupun dokter serta memilih metode pembayaran yang diterima',
      },
      {
        title: 'Daftar Penjualan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631448092/web/1-2-daftar-penjualan_rexnkq.png',
        desc:
          'Fitur ini menampilkan semua riwayat penjualan pada range tanggal yang dipilih. Dengan fitur ini Anda akan sangat mudah mereview setiap transaksi yang berhasil dibuat beserta dengan detailnya. Jika ingin mencetak ulang struk juga dapat dilakukan melalui fitur ini.',
      },
      {
        title: 'Laporan Penjualan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631448091/web/1-3-laporan-penjualan_qu5t4q.png',
        desc:
          'Untuk membantu pengguna dalam menganalisis data penjualan, tersedia juga laporan penjualan yang mana dapat digunakan untuk melihat performa penjualan dari setiap produk, penjualan produk tersebut dilakukan untuk pelanggana mana aja, dan detail lain nya yang sudah dikelompokkan menjadi statistik performa',
      },
      {
        title: 'Analisis Pareto',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631448091/web/1-4-analisis-pareto_wulgtp.png',
        desc:
          'Jika Anda ingin mengelompokkan produk dengan metode pareto, maka fitur ini bisa diandalkan. Anda dapat tahu produk mana saja yang berkontribusi pada omset dan keuntungan apotek. Dengan begitu Anda akan dapat fokus pada produk-produk tersebut untuk meningkatkan pendapatan ataupun keuntungan apotek',
      },
      {
        title: 'Retur Penjualan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631448092/web/1-2-daftar-penjualan_rexnkq.png',
        desc:
          'Jika ada retur dari pelanggan, fitur ini dapat digunakan sehingga. Akan tetapi jika kebijakan apotek tidak menerima pengembalian barang, tentu fitur ini juga bisa disembunyikan',
      },
    ],
  },
  {
    title: 'Pengadaan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369708/web/pengadaan_o7utak.png',
    desc:
      'Tidak perlu ribet lagi dengan aktivitas pengadaan, cukup lihat hasil analisis dan rekomendasi sistem, mudah bukan?',
    childs: [
      {
        title: 'Rencana Pengadaan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450124/web/2-1-rencana-pembelian_lbp9mq.png',
        desc:
          'Ingin melakukan pengadaan? Di apotek Digital, Anda dapat menampung itu semua terlebih dahulu sebelum pesanan dilakukan. Sehingga ketika sales distributor datang, tidak perlu berlama-lama lagi untun kenentukan ingin memesan apa karena semuanya sudah Anda rencanakan. ',
      },
      {
        title: 'Pesanan Pembelian',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450125/web/2-2-pesanan-pembelian_k5e4le.png',
        desc:
          'Setelah perencanaan dibuat, Anda dapat membuat pesanyang mana dengan fitur ini sistem akan memberikan rekomendasi pengadaan',
      },
      {
        title: 'Penerimaan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450124/web/2-3-penerimaan_ehefbz.png',
        desc:
          'Setelah barang tiba dan diterima oleh apotek, maka untuk memasukkan stok barang ke sistem, Anda perlu menginput barang sesuai struk dan kondisi barang yang diterima. Dengan fitur ini, Anda akan dapat menginput semua barang yang diterima secara detail, kemudian juga dapat mengecek harga sebelumnya untuk memastikan harga beli yang masih wajar. Jika memang terdapat perubahan harga beli, dan ingin mengubah harga jual, Anda dapat melakukannya pada tampilan fitur ini',
      },
      {
        title: 'Retur Pembelian',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450124/web/2-3-penerimaan_ehefbz.png',
        desc:
          'Fitur ini memungkinkan Anda untuk mengembalikan stok barang kepada supplier dikarenakan beberapa alasan, barang mendekati ED, barang rusak, ataupun alasan lainnya sesuai dengan kebijakan supplier. Untuk itu setiap melakukan retur, dapat menginput di fitur ini agar semuanya tercatat dengan baik',
      },
      {
        title: 'Laporan Pembelian',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450124/web/2-4-laporan-pembelian_zmsk0d.png',
        desc:
          'Seperti halnya laporan penjualan, pada fitur ini juga memungkinkan Anda untuk meneerima informasi pengadaan secara detail. Pada fitur ini diantaranya ditampilkan statistik per produk dan per supplier, untuk menjadi bahan analisis Anda dalam melakukan pengadaan selanjutnya. Ingin tahu pergerakan harga modal juga dapat dilakukan di sini',
      },
      {
        title: 'Analisis Pengadaan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631450124/web/2-5-analisis-pengadaan_jsl5h3.png',
        desc:
          'Tidak hanya laporan, tapi di Fitur ini kami menyajikan analisis dengan menggunakan riwayat data pembelian. Pada fitur ini Anda dapat melihat status stok saat ini, dan sistem akan memberi saran berapa sebaiknya pengadaan dilakukan',
      },
    ],
  },
  {
    title: 'Persediaan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/inventory_ux5xoe.png',
    desc:
      'Keluar dan masuk stok semuanya tercatat secara otomatis di Apotek Digital. Tidak perlu repot catat stok secara manual,',
    childs: [
      {
        title: 'Daftar Produk',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455030/web/3-1-daftar-produk_nywsvb.png',
        desc:
          'Pada fitur ini Anda dapat mengatur semua produk produk yang tersedia di apotek. Membuat produk baru, mengubahnya, mengahapusnya, dan juga bisa melihat kartu stok dari masing-masing produk. Kartu stok sudah dibuat otomatis oleh sistem setiap dilakukan transaksi terhadap produk tersebut. Jadi tidak perlu repot lagi untuk menuliskan kartu stok secara manual',
      },
      {
        title: 'Defecta',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455029/web/3-3-defecta_on8utg.png',
        desc:
          'Jika ingin mengetahui produk apa saya yang kondisinya dibawah stok minimal, Anda dapat melakukannnya melalui fitur ini. Ini akan sangat memudahkan Anda sendainya hanya ingin melakukan pengadaan untuk produk-produk yang stok nya dibawah stok minimal saja',
      },
      {
        title: 'Stok Kadaluarsa',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455029/web/3-3-defecta_on8utg.png',
        desc:
          'Tidak perlu lagi mengecek satu per satu setiap saat untuk mengetahui produk yang akan kadaluarsa. Dengan fitur ini Anda akan dapat memonitor secara real time kapan saja dan dimana saja untuk semua produk-produk yang mendekati kadalursa atau akan kadaluarsa pada waktu tertentu. Sehingga Anda juga dapat mengantisipasi kerugian Apotek karena ini',
      },
      {
        title: 'Stock Opname',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455030/web/3-2-stock-opname_x4152u.png',
        desc:
          'Ingin menyesuaikan stok fisik dengan stok sistem? maka fitur ini adalah solusinya. Pada fitur ini memungkinkan Anda untuk penyesuaian dan mengecek semua produk untuk meminimalisir kerugian yang diakibatkan oleh stok yang hilang atau ternyata sudah expired.',
      },
      {
        title: 'Perpindahan Stok',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455029/web/3-4-perpindahan-stok_m3t2rr.png',
        desc:
          'Anda punya banyak gudang atau mungkin sering keluar masuk stok ke apotek cabang tanpat melalui transaksi penjualan ataupun pengadaan? Produk ini dapat Anda gunakan untuk memasukkan stok, mengeluarkan stok, dan memindahkan stok antar gudang tanpa melalui transaksi penjualan/pembelian',
      },
      {
        title: 'Laporan Persediaan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455029/web/3-5-laporan-persediaan_oirw88.png',
        desc:
          'Pada fitur ini, Anda akan dapat memantau semua pergerakan stok pada range waktu tertentu. Fitur ini menampilkan ringkasan dari kartu stok semua produk dan bagaimana pergerakannya. Anda dapat memanfaatkan fitur ini untuk mengecek pergerakan stok secara keseluruhan',
      },
    ],
  },
  {
    title: 'Keuangan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369708/web/keuangan_fvl1gx.png',
    desc:
      'Katakan selamat tinggal pada aktifitas menghitung uang secara manual yang sering kali terjadi kesalahan dan berujung kerugian.',
    childs: [
      {
        title: 'Akun Kas',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455691/web/4-1-akun-kas_rtvehb.png',
        desc:
          'Anda punya banyak akun bank? atau mungkin kas apotek tersebar di berberapa tempat? Produk ini dapat Anda gunakan untuk tetap memonitor semua kas Anda agar semuanya dapat dihitung dan memudahkan Anda meng-audit setiap akun kas yang Anda miliki. Dengan menggunakan fitur ini Anda dapat menambahkan unlimited akun kas, mentransfer antar akun, hingga melakukan penyesuaian pada akun tersebut',
      },
      {
        title: 'Buku Kas',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455692/web/4-2-buku-kas_xp1fs5.png',
        desc:
          'Ingin mengetahui transaksi apa saja yang terjadi pada akun kas? Fitur ini dapat Anda gunakan untuk dijadikan pegangan. Keluar masuk uang apotek dapat Anda monitor di fitur ini. Selain itu juga di fitur ini Anda dapat mencatat transaksi lainnya seperti pengeluaran operasional, pemasukan lainnya, yang mana nanti akan digunakan untuk perhitungan pada laporan keuangan yang dibuat secara otomatis',
      },
      {
        title: 'Hutang Piutang',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631455692/web/4-3-hutang-piutang_jbithm.png',
        desc:
          'Ingin mengetahui berapa hutang yang dimiliki oleh apotek ketika melakukan pengadaan? atau ingin tahu berapa hutnag yang belum dibayar oleh pelanggan? itu semua dapat Anda monitor di fitur ini. Ketika sudah melakukan pembayaran atau menerima pembayaran, Anda juga dapat menginputnya melalui fitur ini',
      },
      {
        title: 'Laporan Keuangan',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631197182/web/5-laporan-otomatis_oapmgh.png',
        desc:
          'Laporan keuangan menjadi sangat penting untuk sebuah entitas bisnis. Dengan Apotek Digital, Anda tidak perlu repot lagi menghitung semua transaksi yang ada, karena Apotek Digital sudah menghitungkannya untuk Anda secara otomatis. Tinggal pilih periode mana yang mau diambil, maka laporan keuangan Anda dapat Anda download seketika itu juga.',
      },
    ],
  },
  {
    title: 'Karyawan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369708/web/karyawan_aumuoq.png',
    desc:
      'Biarkan karyawan bekerja dengan sistem. Anda dapat santai sambil memantau aktivitas karyawan Anda dimanapun dan kapanpun,',
    childs: [
      {
        title: 'Daftar Pengguna',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631456453/web/5-1-daftar-pengguna_xu74gn.png',
        desc:
          'Pada fitur ini, akan ditampilkan semua akun yang terdaftar pada Apotek Anda. Jika masih kosong, Anda dapat menambahkan akun untuk setiap karyawan Anda, sehingga merka dapat menggunakan akun tersebut sesuai dengan peran meraka masing-masing dan tanggung jawab masing-masing. Sistem akan otomatis mendeteksi akun yang diguanakn untuk setiap aktivitas. Sehingga monitoring apotek akan menjadi sangat mudah',
      },
      {
        title: 'Roles & Permission',
        image:
          'https://res.cloudinary.com/apotekdigital/image/upload/v1631456453/web/5-2-roles-permissions_u1bpto.png',
        desc:
          'Jika Anda memiliki banyak karyawan di apotek dan ingin memberikan mereka akses yang berbeda-beda, maka fitur ini dapat Anda gunakan untuk memberikan hak akses apa saja yang dapat dimiliki oleh setiap role nya. Jika hak aksess sutatu halaman atau action tidak diberikan, maka fitur / action tersebut tidak akan bisa diakses oleh akun karyawan yang menggunakan role tersebut',
      },
      {
        title: 'Riwayat Transaksi Per Karyawan',
        image: '',
        desc:
          'ingin tahu setiap karyawan itu menjual produk apa saja? Mungkin apotek Anda memiliki target untuk penjualan produk tertentu. Fitur ini cocok banget dimanfaatkan. Anda dapat memonitor siapa yang melakukan setiap transaksinya.',
      },
      {
        title: 'Manajemen Shift',
        image: '',
        desc:
          'Ingin mengetahui pendapatan pada setiap shift nya dan masuknya ke akun mana saja? Fitur ini dapat Anda gunakan untuk memonitor itu semua. Fitu ini juga menampilkan nilai uang yang ada di kasir ketika buka shift, tutup shift dan selisih yang terdapat pada keduanya',
      },
    ],
  },
  {
    title: 'Pelanggan',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369708/web/pelanggan_tyzy0h.png',
    desc:
      'Pelanggan sangatlah berharga untuk bisnis Anda. Merawat pelanggan Anda akan sangat mudah bersama Apotek Digital.',
    childs: [
      {
        title: 'Database Pelanggan',
        image: '',
        desc:
          'Pada fitur ini Anda dapat mengatur data pelanggan unlimited. Data pelanggan sangat penting untuk mengetahui siapa pelanggan yang paling loyal dan Anda dapat memberikan perlakukan khusus untuknya',
      },
      {
        title: 'Laporan Transaksi Per Pelanggan',
        image: '',
        desc:
          'Tiap pelanggan dapat Anda lihat berapa transaksinya dalam selang waktu tertentu. Selain itu juga, Anda dapat mengetahui pelanggan tersebut membeli produk apa saja dengan harga jual berapa.',
      },
    ],
  },
  {
    title: 'Dokter',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369708/web/dokter_ifldza.png',
    desc:
      'Anda sudah bekerja sama dengan dokter-dokter/klinik dan ingin tahu bagaimana performanya? Semua itu ada di Apotek Digital',
    childs: [
      {
        title: 'Database Dokter',
        image: '',
        desc:
          'Pada fitur ini Anda dapat mengatur data dokter unlimited. Data dokter cukup penting untuk mengetahui siapa dokter yang paling sering memberikan referal untuk pelanggan. Khususnya untuk Apotek yang bekerja sama dengan dokter atau klinik, data ini menjadi sangat penting.',
      },
      {
        title: 'Laporan Transaksi Per Dokter',
        image: '',
        desc:
          'Tiap dokter dapat Anda lihat berapa transaksinya dalam selang waktu tertentu. Selain itu juga, Anda dapat mengetahui dengan referal/resep dokter tersebut, membuat transaksi pada produk apa saja dengan harga jual berapa.',
      },
    ],
  },
  {
    title: 'Supplier',
    image:
      'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/supplier_zcxnpx.png',
    desc:
      'Pemilihan supplier yang tepat untuk satu produk tidaklah mudah, oleh karena itu biarkan Apotek Digital yang melakukannya.',
    childs: [
      {
        title: 'Database Supplier',
        image: '',
        desc:
          'Pada fitur ini Anda dapat mengatur data supplier unlimited. Data supplier cukup penting untuk mengevaluasi pengadaan Apotek. Selain itu juga data supplier dapat digunakan untuk pembuatan surat pesanan otoamatis',
      },
      {
        title: 'Laporan Transaksi Per Supplier',
        image: '',
        desc:
          'Ingin tahu sudah belanja produk A itu ke supplier apa aja? atau ingin tahu supplier ini sebelumnya menjual produk apa aja ke Apotek dan berapa harganya? Semua itu dapat dilakukan dengan fitur ini. Bahkan anda dapat membandingkan harga terbaik suatu produk berdasarkan data terdapat pada supplier apa',
      },
    ],
  },
];

const FeaturesPage = () => {
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E022"
          />
        </svg>
      </div>
    );
  };

  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  );

  const [activeMainFeature, setActiveMainFeature] = useState(0);
  const [activeFeature, setActiveFeature] = useState(0);
  const [features, setFeatures] = useState(
    _.get(allFeatures, `[${activeMainFeature}].childs`, []),
  );
  let mainSliderRef = useRef(null);

  useEffect(() => {
    setFeatures(
      _.get(allFeatures, `[${activeMainFeature}].childs`, []),
    );
    setActiveFeature(0);
  }, [activeMainFeature]);
  const productSliderSettings = {
    infinite: false,
    dots: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          centerPadding: '50px',
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-6"
        style={{backgroundColor: 'aliceblue'}}>
        <Helmet>
          <title>Aplikasi Apotek Super Lengkap</title>
          <meta
            name="description"
            content="Fitur yang super lengkap siap membersamai bisnis Apotek Anda untuk tumbuh di era digital. Yuk pelajari lebih lanjut"
          />
          <meta
            name="og:description"
            content="Fitur yang super lengkap siap membersamai bisnis Apotek Anda untuk tumbuh di era digital. Yuk pelajari lebih lanjut"
          />
        </Helmet>
        {renderWave()}
        <div className="container">
          <div className="row py-5">
            <div className="col-12 py-5 text-center ">
              <h1 className=" font-weight-bold">
                Aplikasi Apotek Super Lengkap!
              </h1>
              <br />

              <h6 className=" font-weight-bold">
                Satu Aplikasi cukup untuk membuat bisnis apotekmu
                tumpuh pesat!
              </h6>
            </div>
            <div className="col-12 text-center ">
              <img
                src="https://res.cloudinary.com/apotekdigital/image/upload/c_scale,w_500/v1631314135/web/phone-apps_ftol75.png"
                style={{maxWidth: '100%', width: 500}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderValues2 = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD'}}>
        <Container
          style={{
            ...{marginTop: -80, width: '100%'},
            ...(matches ? {} : {padding: 0}),
          }}>
          <br />
          <Slider
            ref={(val) => {
              mainSliderRef = val;
            }}
            {...productSliderSettings}>
            {(allFeatures || []).map((item, index) => (
              <Grid
                container
                justify="flex-start"
                key={index}
                spacing={matches ? 5 : 1}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{textAlign: 'left'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setActiveMainFeature(index);
                    }}
                    elevation={4}
                    style={{
                      ...{
                        padding: 16,
                        borderRadius: 20,
                        textTransform: 'none',
                        border: 'white solid 1px',
                        marginTop: 10,
                        marginBottom: 20,
                        marginLeft: matches ? 0 : 10,
                        height: '100%',
                        textAlign: 'center',
                      },
                      ...(index === activeMainFeature
                        ? {}
                        : {backgroundColor: 'white'}),
                    }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        filter:
                          activeMainFeature === index
                            ? `brightness(0) invert(1)`
                            : 'none',
                      }}>
                      <img
                        src={item.image}
                        style={{
                          height: 80,
                          marginBottom: 40,
                          marginTop: 30,
                        }}
                      />
                      <h5>{item.title}</h5>
                      <p style={{color: '#8B9BB1'}}>{item.desc}</p>
                    </div>
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Slider>
        </Container>
      </div>
    );
  };

  const renderFeatures = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD'}} className="py-6">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 offset-md-3 text-center d-flex"
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <IconButton
                color="primary"
                disabled={activeMainFeature <= 0}
                aria-label="prev"
                onClick={() => {
                  if (activeMainFeature > 0) {
                    setActiveMainFeature(activeMainFeature - 1);
                    if (_.isFunction(mainSliderRef.slickGoTo))
                      mainSliderRef.slickGoTo(activeMainFeature - 1);
                  }
                }}
                component="span">
                <NavigateBeforeIcon />
              </IconButton>
              <h3>
                {_.get(allFeatures, `[${activeMainFeature}].title`)}
              </h3>
              <IconButton
                color="primary"
                disabled={activeMainFeature >= allFeatures.length - 1}
                aria-label="next"
                onClick={() => {
                  if (activeMainFeature < allFeatures.length - 1) {
                    setActiveMainFeature(activeMainFeature + 1);
                    if (_.isFunction(mainSliderRef.slickGoTo))
                      mainSliderRef.slickGoTo(activeMainFeature + 1);
                  }
                }}
                component="span">
                <NavigateNextIcon />
              </IconButton>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-8 order-sm-2">
              <img
                src={_.get(features, `[${activeFeature}].image`, '')}
                style={{maxWidth: '100%'}}
              />
              <p>{_.get(features, `[${activeFeature}].desc`, '')}</p>
            </div>
            <div className="col-md-4 my-1 ">
              {features.map((item, index) => (
                <Button
                  variant={
                    activeFeature === index ? 'contained' : 'outlined'
                  }
                  key={index}
                  color="primary"
                  onClick={() => setActiveFeature(index)}
                  style={{
                    padding: 16,
                    marginTop: 10,
                    borderRadius: 16,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      filter:
                        activeFeature === index
                          ? `brightness(0) invert(1)`
                          : 'none',
                    }}>
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'left',
                        textTransform: 'none',
                      }}>
                      <h6
                        style={
                          !matches
                            ? {fontSize: 12, marginBottom: 0}
                            : {marginBottom: 0}
                        }>
                        {item.title}
                      </h6>
                    </div>
                  </div>
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSecondClosing = () => {
    return (
      <div style={{backgroundColor: '#FAFAFD', position: 'relative'}}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '50%',
            bottom: 0,
            backgroundColor: 'aliceblue',
          }}
        />
        <div className="container">
          <div className="card px-5">
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h2>
                  Saatnya investasi pada teknologi untuk meningkatkan
                  bisnis apotekmu!
                </h2>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja gratis!
                </p>
                <Link to="/register" className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(250, 250, 253)',
        maxWidth: '100%',
        overflow: 'hidden',
      }}>
      {renderTopContent()}
      {renderValues2()}
      {renderFeatures()}
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'rgb(250, 250, 253)',
        }}>
        <br />
        <br />
        <br />
      </Grid>
      {renderSecondClosing()}
      <Grid
        style={{
          textAlign: 'center',
          backgroundColor: 'aliceblue',
        }}>
        <h5
          style={{
            marginBottom: 20,
            paddingTop: 30,
          }}>
          Ingin tanya-tanya dan ngobrol lebih lanjut ke tim Apotek
          Digital? yuk agendakan meeting/demo gratis dengan tim kami!{' '}
        </h5>
        <a
          target="_blank"
          href="https://wa.me/6285223932189?text=Halo,%20saya%20ingin%20agendakan%20demo%20Apotek%20Digital"
          className="btn btn-green">
          Agendakan Demo Gratis
        </a>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'aliceblue',
          paddingTop: 20,
        }}>
        <Divider
          style={{
            width: '80%',
          }}
        />
      </Grid>

      {/*this.renderModalPromo()*/}
    </div>
  );
};

export default FeaturesPage;
