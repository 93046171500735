import React, {useState, Fragment} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: '#0E70E0',
  },
});

const qnaAbout = [
  {
    q: 'Apa itu Apotek Digital?',
    a:
      'Apotek Digital adalah aplikasi memudahkan seluruh aktivitas di apotek dan toko obat dengan fitur super lengkap seperti keuangan, kasir, manajemen stok, pengadaan, penerimaan, database dan fitur lainnya. Mari #MajuBersamaApotekDigital.',
  },
  {
    q: 'Mengapa saya harus menggunakan Apotek Digital?',
    a:
      'Apotek Digital di design khusus untuk pengelolaan apotek dan toko obat dengan penggunaan yang lebih mudah, efektif, efisien, serta dapat dipantau dimana pun dan kapan pun. Semua dapat anda gunakan dengan harga berlangganan yang lebih kompetitif.',
  },
  {
    q:
      'Saya tertarik mencoba fitur di aplikasi Apotek Digital, bagaimana caranya?',
    a:
      'Jika sudah memiliki tablet android/laptop/komputer Anda dapat langsung mencobanya di website apotekdigital.com. Semua fitur dapat anda gunakan GRATIS selama 14 hari sejak mendaftarkan Apotek di akun Anda. Setelah itu tim sales kami akan menghubungi anda untuk menawarkan dukungan layanan berlangganan dalam menikmati semua fasilitas yang ada pada Apotek Digital.    ',
  },
  {
    q:
      'Bisakah saya menggunakan aplikasi Apotek Digital di Smartphone Android/IOS/Windows Phone?',
    a:
      'Tentu bisa, aplikasi Apotek Digital tersedia versi Web App yang dapat diakses oleh semua jenis gadget selagi dapat membuka browser. Tampilan aplikasi juga sudah kita sesuaikan dengan setiap device yang mengakses.',
  },
  {
    q: 'Paket apa saja yang ditawarkan oleh Apotek Digital?',
    a:
      'Apotek Digital menawarkan beberapa skema paket langganan berdasarkan durasi langganan, mulai dari 1 Bulan, 1 Tahun, 2 Tahun dan 3 Tahun. Setiap paket memiliki akses untuk semua fitur',
  },
  {
    q:
      'Jika ingin berlangganan, Apakah tim Apotek Digital akan mengunjungi Apotek secara langsung untuk training/setup alat?',
    a:
      'Pada umumnya semua persiapan untuk menggunakan Apotek Digital tidak membutuhkan setting khusus. Sehingga ketika ingin berlangganan, tim Apotek Digital akan mendemokan langsung kepada pengguna secara online melalui Video Call dan juga jika terjadi kendala akan dibantu di-setup-kan oleh tim dengan cara mengakses device secara remote.',
  },
  {
    q: 'Apakah tidak ada harga sekali bayar untuk selamanya?',
    a:
      'Untuk saat ini kami belum menyediakan layanan tersebut. Apotek Digital dirancang dengan sistem online yang mengharuskan tim Apotek Digital untuk selalu standby memastikan sistem aman dan dapat digunakan oleh semua pengguna Apotek Digital. Oleh karena itu, sistem berlangganan dibuat agar Apotek Digital dapat terus memberikan pelayanan prima kepada setiap pelanggan. Selain itu kami juga akan terus berinovasi untuk memberikan solusi berupa pengembangan fitur dari setiap permasalahan yagn dialami oleh pelanggan',
  },
  {
    q:
      'Untuk berlangganan, metode pembayaran apa yang tersedia di Apotek Digital?',
    a:
      'Apotek Digital mendukung beberapa metode pembayaran seperti debit, transfer ATM, uang elektronik.',
  },
  {
    q:
      'Apakah Apotek Digital melayani pengiriman perangkat ke luar kota?',
    a:
      'Kami siap melakukan pengiriman ke seluruh Indonesia dengan menyesuaikan servis jasa pengiriman.',
  },
  {
    q:
      'Perangkat apa saja yang harus disiapkan untuk menggunakan Apotek Digital?',
    a:
      'Apotek Digital dapat digunakan hampir di semua device yang dapat menggunakan browser, baik itu Komputer Desktop, Laptop, ataupun Smartphone. Untuk perangkat pelengkap Anda juga dapat menggunakan printer dan barcode scanner dengan berkonsultasi terlebi dahulu kepada kami untuk memastikan perangkat tersebut dapat disupport atau tidak',
  },
  {
    q: 'Apakah spesifikasi minimal untuk perangkat?',
    a:
      'Komputer atau smartphone dengan minimal RAM 2GB, dan sistem operasi Android 5.0 atau Lolipop atau di atas itu',
  },
];

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
const FrequentlyAskedQuestions = () => {
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E022"
          />
        </svg>
      </div>
    );
  };

  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-5"
        style={{backgroundColor: 'aliceblue'}}>
        {renderWave()}
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-12 col-sm-12 py-5 text-md-left text-center order-2 order-md-1">
              <h1 className=" font-weight-bold text-center">
                Halo, ada yang bisa <br />
                kami bantu?
              </h1>
              <p className="text-center" style={{opacity: 0.8}}>
                Rasakan kemudahan mengelolah apotek bersama Apotek
                Digital. <br /> Manfaatkan semua fitur lengkap tanpa
                biaya tambahan!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [indexActive, setIndexActive] = useState(0);

  const mainFAQ = () => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          marginTop: -48,
        }}>
        <div className="container">
          {/* <Paper className={classes.root}> */}
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="primary"
            centered>
            <Tab
              label={<div>Pertanyaan Umum</div>}
              value={0}
              style={
                value === 0
                  ? {
                      color: '#333',
                      backgroundColor: 'white',
                      borderRadius: 30,
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      outline: 'none',
                    }
                  : {textTransform: 'capitalize'}
              }
            />
            <Tab
              label="Cara Daftar"
              value={1}
              style={
                value === 1
                  ? {
                      color: '#333',
                      backgroundColor: 'white',
                      borderRadius: 30,
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      outline: 'none',
                    }
                  : {textTransform: 'capitalize'}
              }
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            {qnaAbout.map((item, index) => (
              <Accordion
                style={{borderRadius: 10}}
                key={index}
                square
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}>
                  <Typography style={{fontSize: 13, fontWeight: 600}}>
                    {item.q}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.a}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                padding: 16,
              }}>
              <h4>Buat Akun Owner</h4>
              <p>
                Untuk menggunakan layanan Apotek Digital, maka yang
                pertama harus dilakukan adalah membuat akun "owner".
                Akun ini nantinya dapat digunakan untuk mendaftarkan
                apotek-apotek kemudian memiliki akses penuh setiap
                apotek yang dibuat di Apotek Digital menggunakan akun
                tersebut. Berikut adalah tiga langakah mudah untuk
                membuat akun Owner
              </p>
              <div>
                <ol type="1">
                  <li>Isi form registrasi</li>
                  <li>
                    Pilih Metode Verifikasi: SMS, WhatsApp atau Email
                  </li>
                  <li>
                    Input Kode OTP yang dikirimkan ke nomor/alamat
                    email yang didaftarkan
                  </li>
                </ol>
              </div>
              <h4>Daftarkan Apotek Menggunakan Akun Owner</h4>
              <p>
                Ketika baru membuat akun, maka belum ada apotek yang
                terdaftar. Untuk mendaftarkan Apotek baru pastikan
                akun sudah terverifikasi baik melalui SMS, WhatsApp,
                ataupun Email.
              </p>
              <div>
                <ol type="1">
                  <li>Klik tambah Apotek</li>
                  <div></div>
                  <li>Isi form pendaftaran Apotek</li>
                  <li>Buat akun Admin untuk Apotek</li>
                </ol>
              </div>
              <h4>Migrasi Data ke Sistem</h4>
              <p>
                Setelah Apotek dan akun admin dibuat, maka akan
                otomatis masuk sebagai admin dan Database produk masih
                dalam keadaan kosong. Untuk itu, langkah selanjutnya
                yang perlu dilakukan adalah meyiapkan database produk.
                Berikut adalah tahapnya
              </p>
              <div>
                <ol type="1">
                  <li>{`Klik Menu Persediaan > Daftar Produk`}</li>
                  <div></div>
                  <li>Klik Tombol Import Produk</li>
                  <li>Download template data produk</li>
                  <li>
                    Masukkan daftar produk pada file excel yang
                    didownload tanpa mengubah formatnya serta ikuti
                    petunjuk yang ada pada tampilan import produk pada
                    aplikasi
                  </li>
                  <li>Upload Data produk</li>
                  <li>
                    Jika tidak ada kesalahan, maka data produk
                    otomatis masuk ke sistem
                  </li>
                </ol>
              </div>
            </div>
          </TabPanel>
          {/* </Paper> */}
        </div>
      </div>
    );
  };

  const renderSecondClosing = () => {
    return (
      <div
        style={{
          backgroundColor: '#FAFAFD',
          position: 'relative',
          paddingTop: 30,
        }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '50%',
            bottom: 0,
            backgroundColor: '#1166D8',
          }}
        />
        <div className="container">
          <div className="card px-5">
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h2>
                  Saatnya investasi pada teknologi untuk meningkatkan
                  bisnis apotekmu!
                </h2>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja dengan paket gratis hingga
                  kapanpun!
                </p>
                <Link to="/register" className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderTopContent()}
      {mainFAQ()}
      {/* {renderSecondClosing()} */}
    </Fragment>
  );
};

export default FrequentlyAskedQuestions;
