import React, {useState, Fragment, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {checkBlastCounter} from 'services/analytics';
import {
  Grid,
  Divider,
  Paper,
  Container,
  useMediaQuery,
  Button,
  Avatar,
  useTheme,
} from '@material-ui/core';
import Slider from 'react-slick';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ComparisonTable from './ComparisonTable';

const packages = [
  {
    title: '1 Bulan',
    priceNett: '320rb',
    term: 'bln',
  },
  {
    title: '1 Tahun',
    priceNett: '2,5jt',
    term: 'thn',
  },
  {
    title: '2 Tahun',
    priceNett: '4,5jt',
    term: '2 thn',
  },
  {
    title: '3 Tahun',
    priceNett: '6,5jt',
    term: '3 thn',
    isPopular: true,
  },
];

const HomePage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const featureSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const testimoniSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const mitraSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const renderWave = () => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
        }}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E022"
          />
        </svg>
      </div>
    );
  };

  const [referer, setReferer] = useState('');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('c');
    const ref = searchParams.get('referer');
    if (ref) {
      setReferer(ref);
    }
    if (code)
      checkBlastCounter({code}).then((res) => {
        if (res.isSuccess) {
          console.log(code + ' berhasil di input');
        }
      });
  }, []);
  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-6"
        style={{
          backgroundColor: 'aliceblue',
          borderRadius: '0 0 10% 10%',
        }}>
        <Helmet>
          <title>Software Apotek Anti Ribet</title>
          <meta
            name="description"
            content="Software apotek berbasis cloud yang mempermudah pengelola ataupun pemilik apotek dalam mengatur dan memonitor kinerja apotek hingga melakukan aktifitas-aktifitas rutin di apotek. Sekarang, pantau apotek bisa dilakukan dimanapun dan kapanpun. Satu Aplikasi untuk kelola semua aktivitas di apotekmu! Yuk #GoDigital bersama Apotek Digital!"
          />
          <meta
            name="og:description"
            content="Software apotek berbasis cloud yang mempermudah pengelola ataupun pemilik apotek dalam mengatur dan memonitor kinerja apotek hingga melakukan aktifitas-aktifitas rutin di apotek. Sekarang, pantau apotek bisa dilakukan dimanapun dan kapanpun. Satu Aplikasi untuk kelola semua aktivitas di apotekmu! Yuk #GoDigital bersama Apotek Digital!"
          />
        </Helmet>
        {renderWave()}
        <div className="container">
          <div className="row py-2">
            <div className="col-12 py-3 text-center order-2 order-md-1">
              <h1
                className="font-weight-bold"
                style={{fontSize: isMobile ? '1.5rem' : '2rem'}}>
                Software Apotek Lengkap dan Mudah
              </h1>

              <h6 className="font-weight-bold">
                Satu Aplikasi untuk kelola semua aktivitas di
                apotekmu!
              </h6>
            </div>
            <div className="col-sm-12 text-center order-2 order-md-2 my-2">
              <a
                target="_blank"
                href="https://wa.me/6285223932189?text=Halo,%20saya%20ingin%20agendakan%20demo%20Apotek%20Digital"
                className="btn btn-warning">
                Agendakan Demo Gratis
              </a>
            </div>
            <div className="col-12 text-center order-1 order-md-2">
              <img
                src="/images/ad-desk.png"
                style={{maxWidth: '100%', width: 500}}
              />
              <div>
                <Link
                  to={{
                    pathname:
                      'https://apotekdigital.com/playstore?r=web',
                  }}
                  style={{color: 'white'}}
                  target="_blank">
                  <img src="/google-play-badge.png" height={60} />
                </Link>
                <Link
                  to={{
                    pathname:
                      'https://apotekdigital.com/appstore?r=web',
                  }}
                  style={{color: 'white'}}
                  target="_blank">
                  <img
                    src="/download-on-the-app-store.svg"
                    height={40}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const products = [
    {
      title: 'Mudah Digunakan',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/mudah-digunakan_m9mwu7.png',
      desc:
        'Apotek Digital di-desain agar dapat digunakan dengan mudah, oleh siapapun yang menggunakannya.',
    },
    {
      title: 'Fitur Lengkap',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/fitur-lengkap_jlgelt.png',
      desc:
        'Fitur yang lengkap di Apotek Digital siap membantu pengusaha apotek untuk lebih fokus kepada hal-hal strategis.',
    },
    {
      title: 'Harga Terjangkau',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/hemat_ve1cmy.png',
      desc:
        'Kami sangat paham merintis bisnis Apotek tidaklah murah, dengan harga terjangkau, kami siap untuk tumbuh bersama',
    },
    {
      title: 'Multi Platform',
      image:
        'https://res.cloudinary.com/apotekdigital/image/upload/v1631369709/web/multi-platform_cglizb.png',
      desc:
        'Tidak perlu repot mencari device yang cocok, karena Apotek Digital dapat diakses di sistem operasi apapun di gadget Anda.',
    },
  ];
  const productSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          centerPadding: '50px',
          slidesToScroll: 2,
        },
      },
    ],
  };
  const pricesSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // className: 'center',
          // centerMode: true,
          slidesToShow: 1.5,
          centerPadding: '50px',
          slidesToScroll: 1,
        },
      },
    ],
  };
  const matches = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  );
  const renderValues2 = () => {
    return (
      <div style={{backgroundColor: '#fff'}}>
        <Container
          style={{
            ...{marginTop: -100, width: '100%'},
            ...(matches ? {} : {padding: 0}),
          }}>
          <br />
          <Slider {...productSliderSettings}>
            {(products || []).map((item, index) => (
              <Grid
                container
                justify="center"
                key={index}
                spacing={matches ? 5 : 1}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{textAlign: 'center'}}>
                  <Paper
                    elevation={2}
                    style={{
                      padding: 16,
                      borderRadius: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      marginLeft: matches ? 0 : 10,
                      height: '100%',
                      textAlign: 'center',
                    }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <img
                        src={item.image}
                        style={{
                          height: 80,
                          marginBottom: 40,
                          marginTop: 30,
                        }}
                      />
                      <h5>{item.title}</h5>
                      <p style={{color: '#8B9BB1'}}>{item.desc}</p>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Slider>
        </Container>
      </div>
    );
  };

  const testinomiContent = [
    {
      name: 'Apt. Safira',
      role: 'Apotek Nuril, Kalimantan Selatan',
      content: `Apotek Digital sangat membantu dalam manajemen Apotek. Mulai dari manajemen stok untuk mencegah kekosongan barang dan manajemen keuangan untuk mencegah adanya kerugian.
      Apotek Digital juga bisa melihat omset, profit, dan jumlah pasien perharinya.
      
      selain itu, Apotek Digital sangat praktis digunakan kapanpun dan dimanapun berada.
      
      Dengan banyaknya kelebihan dari Apotek Digital, maka tidak perlu pusing dengan manajemen apotek dan bisa lebih fokus dalam pelayanan terhadap pasien.
      `,
      imageUrl: '/images/testimoni/apotek-nuril.jpg',
    },
    {
      name: 'Apt. Fitria Iriani',
      role: 'Apotek Yunas Farma',
      content: `Setelah menggunakan Apotek Digital, managemen keuangan apotek kami jadi lebih bagus. Awalnya mau pakai kartu stok manual, tapi karena Apotek Digital dilengkapi fitur itu jadi agak mengurangi keribetan apotek kami. Dengan Apotek Digital bisa meriksa stok obat tiap hari. :D pengadaan obat jadi lebih mudah. Sistemnya mudah dipahami, jadi dijelaskan seklai oleh admin bisa langsung paham. Sukses terus Apotek Digital. Recommended banget pokoknya untuk apotek yang baru merintis`,
      imageUrl: '/images/testimoni/apotek-yunas-farma.jpg',
    },
    {
      name: 'Muhammad Kholiq Hidayat',
      role: 'Apotek Nusantara',
      content:
        'Makasih Apotek Digital, terbantu banget dengan sistemnya yang mudah dipahami, harganya terjangkau, dan udah ga ribet lagi menggunakan kartu stok manual karena di Apotek Digital dilengkapi dengan banyak fitur yang memudahkan dalam memantau stock obat defecta, stok ED bisa terpantau dengan baik. Recommended banget deh pokoknya untuk apotek2 yang butuh kemudahan dalam mengelola apoteknya',
      imageUrl: '/images/testimoni/apotek-nusantara.jpg',
    },
    {
      name: 'Apoteker Zaini',
      role: 'Apotek 1000 Sehat',
      content: `1 Tahun yang Lalu Kami Mulai Berbisnis Apotek yang diberi Nama Apotek 1000 Sehat. Berawal dari 3 Apoteker Pendiri dan Modal Minim, Rasa Pesimis dan Optimis menjadi Campur Aduk selama Menjalani Bisnis ini.

        Gak Kebayang!!! Awalnya Jumlah Item Obatnya cuma 100-200 Item dan sekarang sudah 1000an Item Obat di tambah Harus Jeli menjaga CashFlow agar Tetap SEHAT Keuangan dan Stok Obat. Tetapi Semua itu Menjadi MUDAH semenjak Menggunakan Aplikasi Apotek Digital.
        
        Sekarang, kami TIDAK TAKUT lagi Kehabisan Stok Obat, Membaca CASH FLOW keuangan, Monitor Omzet & Profit Harian, Semua Manajemen Apotek dibantu sama Apotek Digital. Dimanapun dan Kapanpun bisa di Akses lewat Laptop/PC maupun Handphone kita, Serasa Bisnis Apotek kita dalam Genggaman. Terima Kasih Apotek Digital 😀`,
      imageUrl: '/images/testimoni/apotek-1000sehat.jpg',
    },
    {
      name: 'Apotek Kaffah Farma Padang',
      role: '',
      content: `Terimakasih apotek digital, sangat membantu saya dalam operasional apotek mulai dari manajemen stock, keuangan, hingga sistem kasirnya. Frontliner nya juga sigap membantu.`,
      imageUrl: '/images/testimoni/default-male.jpg',
    },
    {
      name: 'Fajar L Faisal',
      role: 'Apotek Bayu Endah',
      content: `Apotek digital sangat membantu mengontrol jalannya apotek sehingga mempermudah kita buat mengontrol pengelolaan apotek jadi lebih mudah, ga  pusing lagi masalah pembukuan dan pengadaan barang apotek. Terimakasih banyak Apotek Digital semoga terus menjadi yang terbaik. Gaaaskeun..`,
      imageUrl: '/images/testimoni/apotek-bayuendah.jpg',
    },
    {
      name: 'Pak Habibie',
      role: 'Apotek Medisina',
      content: `Aplikasi apotik digital sangat simple dan mudah.. kami pihak apotek medisina Banda Aceh sangat senang memakai aplikasi ini karena semua management transaksi di apotek menjadi lebih jelas. Management apotek terlaksana tanpa ribet`,
      imageUrl: '/images/testimoni/apotek-medisina.jpg',
    },
    {
      name: 'Dita Harta Purwanti',
      role: 'Danta Medika',
      content: `Smnjak menggunakan Apotek Digital ngurus apotek jadi lebih mudah.. aplikasiny beneran bagus.. mempermudah kerja.. bisa dibuka di rumah, di jalan, dimana2😂. Aplikasiny jg gak ribet.. pokoknya mantap`,
      imageUrl: '/images/testimoni/apotek-dantamedika.jpg',
    },
    {
      name: 'Teguh',
      role: 'Apotek Soraya',
      content: `Aplikasinya simple, mudah dipahami, oke banget! Usul sih namanya dibuat universal aja, jadi bisa dipakai di semua bisnis`,
      imageUrl: '/images/testimoni/teguh.jpg',
    },
    {
      name: 'Nuraeni',
      role: 'Apotek Dewaruci',
      content: `Selama saya buka apotek, dari tahun 2012, saya merasa kesulitan untuk mengontrol stok obat dengan kartu stok manual. Setelah saya pakai apotek digital, alhamdulillah bisa mengontrol stok obat lebih baik. Dan hebatnya lagi bisa stock opname sewaktu-waktu dalam waktu lebih cepat dan realtime`,
      imageUrl: '/images/testimoni/dewaruci.jpg',
    },
    {
      name: 'Algi',
      role: 'Apotek Meidy Farma',
      content:
        'Apotek Digital memudahkan saya dalam berbagai hal dalam bisnis apotek. Fiturnya sanga lengkap simple, lengkap, dan user-friendly.',
      imageUrl: '/images/testimoni/default-male.jpg',
    },
    {
      name: 'Muthmainnah',
      role: 'Apotek Sejahtera Medika',
      content:
        'Selama menggunakan Apotek Digital banyak sekali kemudahannya. Yang bikin saya tertarik terutama fitur input barang (faktur pembelian) sangat mudah digunakan simple dan jelas. Kemudahan lainnya bisa diakses di seluruh perangkat tanpa dibatasi. Sehingga saya bisa mengontrol apotek dari luar. Adanya akun masing-masing karyawan juga sangat membantu. Saat ada barang yang selisih/karyawan salah input barang. Scan barcode dari hp juga sangat membantu karyawan. Dalam fitur analisis pembelian juga sangat membantu saya saat mau order barang',
      imageUrl: '/images/testimoni/mumut.jpg',
    },
    {
      name: 'Endah',
      role: 'Apotek Gading Raya',
      content:
        'Apotek Digital sangat membantu sekali dalam pengadaan obat dan pemantauan dimanapun berada. CS nya benar-benar fast response, kalau ada kesulitan segera dibantu.',
      imageUrl: '/images/testimoni/default-female.jpg',
    },
    {
      name: 'Ferry',
      role: 'Apotek Sinar Wangi',
      content:
        'Bagus sekali aplikasi Apotek Digital. Baru coba tapi update fitur nya mulai dilegkapi sesuai usulan fitur dari konsumen. Bagus sekali bisa usulkan fitur-fitur untuk diaplikasikan di apotek',
      imageUrl: '/images/testimoni/ferry.jpg',
    },
    {
      name: 'Shofwan Muis',
      role: 'Apotek Medifarma',
      content:
        'Layanan support Apotek Digital luarbiasa keren. Soalnya sampai malam juga dibalas ketika bertanya',
      imageUrl: '/images/testimoni/shofwan.jpg',
    },
    {
      name: 'Romzi',
      role: 'Apotek Mahir Farma',
      content:
        'Apotek Digital aplikasinya selama ini menurut saya keren, kalau dibanding aplikasi lain. Mantap lah! Fitur nya fit banget sama bisnis apotek, dari fitur satuan sih saya tidak nemu di aplikasi lain, serta fitur perbandingan harga PBF. KEREN!',
      imageUrl: '/images/testimoni/default-male.jpg',
    },
  ];
  const renderTestimoni = () => {
    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          marginBottom: 100,
          maxWidth: '100%',
          marginTop: 40,
        }}>
        <Container style={{}}>
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <div className="sub-title">TESTIMONI PENGGUNA</div>
              <h3>Apa Kata Mereka?</h3>
            </div>
          </div>
          <br />
          <div className="container">
            <Slider {...testimoniSliderSettings}>
              {(testinomiContent || []).map((item, index) => (
                <Grid container justify="flex-start" key={index}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{textAlign: 'left'}}>
                    {
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          textAlign: 'center',
                          padding: 10,
                        }}>
                        {item.imageUrl && (
                          <img
                            src={item.imageUrl}
                            style={{
                              height: 100,
                              marginBottom: 40,
                              marginTop: 30,
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        <p>{`"${item.content}"`}</p>
                        <h5>{item.name}</h5>
                        <p style={{color: '#8B9BB1'}}>{item.role}</p>
                      </div>
                    }
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    );
  };

  const mitraContents = [
    {
      imageUrl: '/images/mitra/apotekqueen.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekraja.jpg',
    },
    {
      imageUrl: '/images/mitra/kurniafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/bumameapotek.jpg',
    },
    {
      imageUrl: '/images/mitra/adhigana.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekdara.jpg',
    },
    {
      imageUrl: '/images/mitra/agafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekorison.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekwunemo.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekditaputra.jpg',
    },
    {
      imageUrl: '/images/mitra/apoteksyifa.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekmtm.jpg',
    },
    {
      imageUrl: '/images/mitra/fitrifarma.jpg',
    },
    {
      imageUrl: '/images/mitra/indragirifarma.jpg',
    },
    {
      imageUrl: '/images/mitra/bintangsemestafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/semestasehat.jpg',
    },
    {
      imageUrl: '/images/mitra/wargi.jpg',
    },
    {
      imageUrl: '/images/mitra/senyum.jpg',
    },
    {
      imageUrl: '/images/mitra/terameka.jpg',
    },
    {
      imageUrl: '/images/mitra/wongkito.jpg',
    },
    {
      imageUrl: '/images/mitra/soraya.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekdiana.jpg',
    },
    {
      imageUrl: '/images/mitra/taherfarma.jpg',
    },
    {
      imageUrl: '/images/mitra/yunasfarma.jpg',
    },
    {
      imageUrl: '/images/mitra/yashfarmacia.jpg',
    },
    {
      imageUrl: '/images/mitra/phileo.jpg',
    },
    {
      imageUrl: '/images/mitra/selaras.jpg',
    },
    {
      imageUrl: '/images/mitra/nyiurhijau.jpg',
    },
    {
      imageUrl: '/images/mitra/klinikqueen.jpg',
    },
    {
      imageUrl: '/images/mitra/reginafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/pelaiharisehat.jpg',
    },
    {
      imageUrl: '/images/mitra/asheqa.jpg',
    },
    {
      imageUrl: '/images/mitra/kharisma.jpg',
    },
    {
      imageUrl: '/images/mitra/kuncimedika.jpg',
    },
    {
      imageUrl: '/images/mitra/jjayasyifa.jpg',
    },
    {
      imageUrl: '/images/mitra/mentarifarma.jpg',
    },
    {
      imageUrl: '/images/mitra/megafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/aptjatinegara.jpg',
    },
    {
      imageUrl: '/images/mitra/palabuan.jpg',
    },
    {
      imageUrl: '/images/mitra/binusfarma.jpg',
    },
    {
      imageUrl: '/images/mitra/berkatutamafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekaurora.jpg',
    },
    {
      imageUrl: '/images/mitra/barokahmedika.jpg',
    },
    {
      imageUrl: '/images/mitra/binafarma.jpg',
    },
    {
      imageUrl: '/images/mitra/sesama.jpg',
    },
    {
      imageUrl: '/images/mitra/baladewa.jpg',
    },
    {
      imageUrl: '/images/mitra/hiro.jpg',
    },
    {
      imageUrl: '/images/mitra/andrio.jpg',
    },
    {
      imageUrl: '/images/mitra/dahlanfarma.jpg',
    },
    {
      imageUrl: '/images/mitra/indosehat.jpg',
    },
    {
      imageUrl: '/images/mitra/pmbekanova.jpg',
    },
    {
      imageUrl: '/images/mitra/naftalifarma.jpg',
    },
    {
      imageUrl: '/images/mitra/pcbrawa.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekhermantoni.jpg',
    },
    {
      imageUrl: '/images/mitra/haper.jpg',
    },
    {
      imageUrl: '/images/mitra/gambutsehat.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekbcm.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekassyifa1.jpg',
    },
    {
      imageUrl: '/images/mitra/apotekasli.jpg',
    },
    {
      imageUrl: '/images/mitra/anf.jpg',
    },
    {
      imageUrl: '/images/mitra/meydifarma.jpg',
    },
    {
      imageUrl: '/images/mitra/afiatutama.jpg',
    },
    {
      imageUrl: '/images/mitra/abadipharma.jpg',
    },
  ];
  const renderMitra = () => {
    return (
      <div style={{backgroundColor: '#ffffff'}} className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center mb-4">
              <div className="sub-title">Mitra Apotek Digital</div>
              <h3>Ratusan Mitra Tersebar Di Indonesia</h3>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-12 mb-3 mt-2 text-left px-4"
              style={{fontSize: 20}}>
              <Slider {...mitraSliderSettings}>
                {(mitraContents || []).map((item, index) => (
                  <Grid container justify="flex-start" key={index}>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{textAlign: 'left'}}>
                      {
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: 10,
                          }}>
                          <img
                            src={item.imageUrl}
                            style={{
                              height: 100,
                              marginBottom: 40,
                              marginTop: 30,
                            }}
                          />
                        </div>
                      }
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </div>
            {/* <div className="col-12 mb-5 mt-5">
              <ComparisonTable />
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderFirstClosing = () => {
    return (
      <div
        style={{
          backgroundColor: 'aliceblue',
          position: 'relative',
          borderRadius: 50,
          margin: 20,
          overflow: 'hidden',
        }}>
        {renderWave()}

        <div className="container">
          <div className="row">
            <div className="col-md-6 text-md-left text-center py-4 py-md-6 order-2 order-md-1">
              <div className="sub-title">
                Permudah Bisnis Apotek Anda
              </div>
              <h4>
                Saatnya investasi teknologi untuk mempermudah bisnis
                apotek Anda!
              </h4>
              <p className="text-white" style={{opacity: 0.8}}></p>
              <Link
                to={`/register${
                  referer ? '?referer=' + referer : ''
                }`}
                className="btn btn-green">
                Daftar Sekarang
              </Link>
            </div>
            <div className="col-md-6 text-center py-4 px-4 order-1 order-md-2">
              <div className="row">
                <div
                  className="col-12 mt-1"
                  style={{
                    borderRadius: 20,
                    overflow: 'hidden',
                    width: '90%',
                    paddingTop: `56%`,
                    border: '5px solid #1166d855',
                  }}>
                  <iframe
                    // width="100%"
                    // height="600"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    src="https://www.youtube.com/embed/sUOsVbBxybk"
                    title="Apotek Digital - Software Apotek Terbaik di Indonesia"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [activeFeature, setActiveFeature] = useState(0);

  const featuresSlider = (content) => (
    <Slider {...featureSliderSettings}>
      {(content || []).map((item, index) => (
        <Grid container justify="flex-start" key={index}>
          <Grid item md={12} xs={12} style={{textAlign: 'left'}}>
            {
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: 10,
                }}>
                <img
                  src={item.imageUrl}
                  style={{
                    height: 200,
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                />

                <h6 style={{fontSize: 11}}>{item.title}</h6>
                {/* <p>{`"${item.body}"`}</p> */}
              </div>
            }
          </Grid>
        </Grid>
      ))}
    </Slider>
  );
  const features = [
    {
      title: 'Catat Semua Transaksi Apotek',
      desc:
        'Semua transaksi di apotek dapat Anda input hanya dengan satu aplikasi. Tidak perlu lagi software lainnya!',
      points: [
        'Kasir/Penjualan',
        'Retur Penjualan',
        'Pembelian',
        'Retur Pembelian',
        'Konsinyasi',
        'Bayar Utang-Piutang',
        'Biaya Operasional',
        'Transaksi Lainnya',
      ],
      slidesContents: [
        {
          imageUrl: '/images/features/cashier.jpg',
          title: 'Kasir',
        },
        {
          imageUrl: '/images/features/purchase-invoice.jpg',
          title: 'Faktur Pembelian',
        },
        {
          imageUrl: '/images/features/payable-payment.jpg',
          title: 'Pembayaran Utang',
        },
      ],
    },
    {
      title: 'Dashboard Informatif',
      desc:
        'Pantau dan ukur performa apotek jadi jauh lebih mudah dengan multi dashboard yang informatif',
      points: [
        'Dashboard Umum',
        'Dashboard Penjualan',
        'Dashboard Pembelian',
        'Dashboard Persediaan',
      ],
      slidesContents: [
        {
          imageUrl: '/images/features/dashboard-penjualan.jpg',
          title: 'Dashboard Penjualan',
        },
        {
          imageUrl: '/images/features/dashboard-pembelian.jpg',
          title: 'Dashboard Pembelian',
        },
        {
          imageUrl: '/images/features/dashboard-persediaan.jpg',
          title: 'Dashboard Persediaan',
        },
      ],
    },
    {
      title: 'Laporan Detail & Otomatis',
      desc:
        'Laporan detail otomatis dibuatkan oleh sistem dan datanya pun bisa ditarik/dieksport dengan mudah.',
      points: [
        'Kartu Stok Digital',
        'Laporan Shift Jaga',
        'Laporan Penjualan',
        'Laporan Pembelian',
        'Laporan Persediaan',
        'Laporan Keuangan',
        'Laporan Utang-Piutang',
      ],
      slidesContents: [
        {
          imageUrl: '/images/features/stock-card.jpg',
          title: 'Kartu Stok',
        },
        {
          imageUrl: '/images/features/sales-report.jpg',
          title: 'Laporam Penjualan',
        },
        {
          imageUrl: '/images/features/financial-report.jpg',
          title: 'Laporan Keuangan',
        },
      ],
    },
    {
      title: 'Analisis Apotek Menjadi Mudah',
      desc:
        'Analisis data otomatis dilakukan secara realtime akan sangat membantu Anda untuk membuat keputusan bisnis yang lebih cermat menggunakan data',
      points: [
        'Analisis Pareto',
        'Analisis Pembelian',
        'Analisis Harga Produk',
        'Bandingkan Harga Supplier',
      ],
      slidesContents: [
        {
          imageUrl: '/images/features/pareto-analysis.jpg',
          title: 'Analisis Pareto',
        },
        {
          imageUrl: '/images/features/purchase-analysis.jpg',
          title: 'Analisis Pembelian',
        },
        {
          imageUrl: '/images/features/price-analysis.jpg',
          title: 'Analisis Harga',
        },
      ],
    },
    {
      title: 'Atur Hak Akses Pengguna',
      desc:
        'Atur hak akses karyawan dengan custom sesuai dengan peran dan tanggung jawab yang dimilikinya untuk Apotek. Data & informasi sensitif Apotek Anda tetap aman!',
      points: [
        'Batasi Hak Akses Halaman',
        'Batasi Hak Akses Data',
        'Batasi Hak Akses Lainnya',
      ],
      slidesContents: [
        {
          imageUrl: '/images/features/roles-and-permissions.jpg',
          title: 'Hak Akses Berdasarkan Peran',
        },
        {
          imageUrl: '/images/features/user-editor.jpg',
          title: 'Buat Akun Tiap Karyawan',
        },
      ],
    },
  ];
  const renderFeatures = () => {
    const bgcolors = [
      'aliceblue',
      'ghostwhite',
      'papayawhip',
      'mystyrose',
      'lavender',
      'honeydew',
    ];

    return (
      <div style={{backgroundColor: '#fff'}} className="py-4 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 text-center mb-4">
              <div className="sub-title">FITUR</div>
              <h3>Fitur Unggulan dari Apotek Digital</h3>
            </div>
          </div>
          {features.map((feature, index) => (
            <div
              key={index}
              className="row text-center my-2"
              style={{
                backgroundColor:
                  (index + 1) % 2 === 0 ? bgcolors[0] : 'none',
                padding: 10,
                borderRadius: 10,
              }}>
              <div
                className={`col-md-6 mt-1 px-4 ${
                  (index + 1) % 2 === 0 ? 'order-md-2' : ''
                }`}
                style={{
                  borderRadius: 20,
                  overflow: 'hidden',
                  maxWidth: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  // paddingTop: `46%`,
                  // border: '5px solid #1166d8',
                }}>
                {featuresSlider(feature.slidesContents)}
              </div>
              <div
                className={`col-md-6 mb-3 mt-2 text-left px-4 ${
                  (index + 1) % 2 === 0 ? 'order-md-1' : ''
                }`}
                style={{fontSize: 14}}>
                <h5>{feature.title}</h5>
                <strong>{feature.desc}</strong>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="fa-ul">
                      {(feature?.points || [])
                        .slice(0, 4)
                        .map((point) => (
                          <li>
                            <i className=" text-primary fa-li fa fa-check-circle" />
                            {point}
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="fa-ul">
                      {(feature?.points || [])
                        .slice(4, 8)
                        .map((point) => (
                          <li>
                            <i className=" text-primary fa-li fa fa-check-circle" />
                            {point}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 mb-5 mt-5">
              <ComparisonTable />
            </div> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPricing = () => {
    return (
      <div
        style={{
          backgroundColor: 'aliceblue',
          paddingBottom: 50,
          position: 'relative',
        }}>
        <div className="container">
          <Container
            style={{
              ...{
                marginTop: 0,
                paddingTop: 100,
                width: '100%',
              },
            }}>
            <Grid className="text-center mb-2">
              <h2>Paket Berlangganan</h2>
            </Grid>

            <Slider {...pricesSliderSettings}>
              {packages.map((product, index) => (
                <Grid
                  item
                  className="pa-1"
                  style={{width: '100%'}}
                  key={product.term}>
                  <Paper
                    style={{
                      padding: 16,
                      margin: 10,
                      textAlign: 'center',
                      borderRadius: 10,
                    }}>
                    <h3>{product.title}</h3>
                    <p style={{color: '#8B9BB1'}}>
                      Akses Semua Fitur
                    </p>
                    <div style={{height: 30}}>
                      {product.isPopular && (
                        <span className="badge badge-success">
                          MOST POPULAR
                        </span>
                      )}
                    </div>
                    <h5
                      style={{
                        textDecoration: 'line-through',
                        marginTop: 30,
                        color: '#AAA',
                      }}>
                      {product.price}
                    </h5>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 10,
                      }}>
                      <div>
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: '#AAA',
                          }}>
                          Rp
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          marginBottom: 20,
                        }}>
                        <h1
                          className="text-default"
                          style={{
                            marginBottom: 0,
                            fontWeight: 1000,
                            color: '#09A599',
                          }}>
                          {product.priceNett}
                        </h1>
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: '#AAA',
                          }}>
                          /{product.term}
                        </div>
                      </div>
                    </div>
                    <Link
                      to={`/register${
                        referer ? '?referer=' + referer : ''
                      }`}
                      className="btn btn-default btn-sm ">
                      Coba Gratis 14 Hari
                    </Link>
                  </Paper>
                </Grid>
              ))}
            </Slider>
            <Grid item style={{textAlign: 'center'}}>
              <h5
                style={{
                  marginBottom: 20,
                  marginTop: 30,
                }}>
                Ingin tanya-tanya dan ngobrol lebih lanjut ke tim
                Apotek Digital? yuk agendakan meeting/demo gratis
                dengan tim kami!{' '}
              </h5>
              <a
                target="_blank"
                href="https://wa.me/6285223932189?text=Halo,%20saya%20ingin%20agendakan%20demo%20Apotek%20Digital"
                className="btn btn-warning"
                style={{zIndex: 3}}>
                Agendakan Demo Gratis
              </a>
            </Grid>
          </Container>
        </div>
      </div>
    );
  };
  const renderSecondClosing = () => {
    return (
      <div style={{backgroundColor: 'white'}}>
        <div className="container">
          <div
            className="card px-5"
            style={{backgroundColor: '#0E70E022', borderRadius: 16}}>
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h4>
                  Semua aktivitas apotek Anda akan jauh lebih mudah
                  menggunakan Apotek Digital
                </h4>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja gratis!
                </p>
                <Link
                  to={`/register${
                    referer ? '?referer=' + referer : ''
                  }`}
                  className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        // backgroundColor: 'rgb(250, 250, 253)',
        maxWidth: '100%',
        overflow: 'hidden',
      }}>
      {renderTopContent()}
      {renderValues2()}
      {renderMitra()}
      {renderFirstClosing()}
      {renderFeatures()}

      {renderSecondClosing()}
      {renderTestimoni()}
      {renderPricing()}
    </div>
  );
};

export default HomePage;
