import 'react-hot-loader';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history';

import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';
import {isLocalhost} from 'helper/functions';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react';

import reducers from './redux/reducers';
import sagas from './redux/sagas';
import * as serviceWorker from './serviceWorker';
// import Loadable from 'react-loadable';

import moment from 'moment';
import 'moment/locale/id';
// biar semua moment pake bahasa indonesia

import axios from 'axios';
axios.defaults.timeout === 300000;

// app styles
import './global.scss';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Loader from 'components/layout/Loader';
moment.locale('id');

import Router from './router';
// const Router = lazy(() => import('./router'));
// const Router = Loadable({
//   loader: () => import('./router'),
//   loading: Loader,
// });

const lisLocalhost = isLocalhost();

// import {offline} from '@redux-offline/redux-offline';
// import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

Bugsnag.start({
  appVersion: process.env.REACT_APP_CURRENT_GIT_SHA,
  apiKey: 'ccfc8bed94fb896af21ee734623bcb39',
  releaseStage: process.env.REACT_APP_CUSTOM_ENV,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    if (lisLocalhost) {
      return false;
    }
    return true;
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React,
);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: storage,
    whitelist: ['settings', 'search'],
  },
  reducers(history),
);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middlewares),
    // offline(offlineConfig),
  ),
);
sagaMiddleware.run(sagas);

const persistor = persistStore(store);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <ErrorBoundary>
      {/* <AbilityContext.Provider value={ability}> */}
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history} />
          </PersistGate>
        </Provider>
      </Suspense>
      {/* </AbilityContext.Provider> */}
    </ErrorBoundary>,
    rootElement,
  );
} else {
  ReactDOM.render(
    <ErrorBoundary>
      {/* <AbilityContext.Provider value={ability}> */}
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history} />
          </PersistGate>
        </Provider>
      </Suspense>
      {/* </AbilityContext.Provider> */}
    </ErrorBoundary>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export {store, persistor, history};
