import React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#1166d8',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}

const rows = [
  createData(
    'Memantau Aktifitas Apotek',
    'Dimanapun dan Kapanpun',
    'Harus ada di apotek',
  ),
  createData(
    'Catat Kartu Stok',
    'Otomatis & Akurat',
    'Manual & Sering lupa',
  ),
  createData(
    'Mengecek Stok Kadaluarsa',
    'Cukup lihat Aplikasi',
    'Harus cek satu per satu',
  ),
  createData(
    'Buat Laporan Keuangan',
    'Otomatis & Akurat',
    'Manual & Tidak Akurat',
  ),
  createData(
    'Buat Surat Pesanan',
    'Otomatis, tinggal print lalu tanda tangan',
    'Manual & Sangat Merepotkan',
  ),
  createData(
    'Merekap Penjualan Berdasarkan Pelanggan',
    'Otomatis, Sangat Detail dan disertai grafik',
    'Manual & Sangat Merepotkan',
  ),
  createData(
    'Mengecek Penjualan oleh Tiap Karyawan',
    'Sangat Detail dan disertai grafik',
    'Manual & Sangat Merepotkan',
  ),
  createData(
    'Mengalisis Perubahan Harga Modal',
    'Otomatis, akurat, dan ada rekomendasi perubahan harga jual',
    'Manual & Sering terlewat',
  ),
  createData(
    'Rekomendasi Pembelian',
    'Rekomendasi Valid Berdasarkan Data',
    'Tidak ada',
  ),
  createData(
    'Analisis Pareto',
    'Otomatis & Akurat',
    'Manual & Merepotkan',
  ),
  createData(
    'Atur Hak Akses/Otorisasi Karyawan',
    'Dapat diatur untuk setiap akun karyawan sesuai perannya',
    'Tidak Bisa',
  ),
  createData(
    'Mencatat Transaksi ketika Ramai',
    'Lebih Efisien, Dapat Dilakukan oleh Beberapa Pengguna dan Beberapa Perangkat di saat bersamaan',
    'Tidak Efisien, Repot, sering lupa tercatat, akhirnya susah direkap dan banyak selisih',
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

export default function ComparisonTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <div style={{fontWeight: 'bold'}}>
                Aktivitas di Apotek
              </div>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{fontWeight: 'bold'}}>
              Tanpa Apotek Digital
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{fontWeight: 'bold'}}>
              Dengan Apotek Digital
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{fontWeight: 'bold', fontStyle: 'italic'}}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell style={{color: 'red'}} align="left">
                {row.fat}
              </StyledTableCell>
              <StyledTableCell
                style={{color: 'green', fontWeight: 'bold'}}
                align="left">
                {row.calories}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
