// import firebaseAuth from '@react-native-firebase/auth';
// import database from '@react-native-firebase/database';
import {functionUrl} from 'helper/functions';
import axios from 'axios';
import {
  firebaseAuth,
  databaseRef,
  databaseDataRef,
  storageRef,
  serverTimestamp,
} from 'services/firebase';

import _ from 'lodash';
// import {ability} from 'helper/ability';

// const databaseRef = database().ref();

export async function login(email, password) {
  try {
    const data = await firebaseAuth().signInWithEmailAndPassword(
      email,
      password,
    );
    return {
      status: 'success',
      data,
    };
  } catch (error) {
    // console.log('error dari user services', error);
    return {status: 'error', error};
  }
}

export async function loginWithToken(token) {
  try {
    await firebaseAuth().signInWithCustomToken(token);
    return {
      status: 'success',
    };
  } catch (error) {
    return {status: 'error', error};
  }
}

export async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
  return getCurrentUser(firebaseAuth());
}

export async function getFirebaseIdToken() {
  try {
    let currentUser = firebaseAuth().currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      return token || null;
    } else {
      //attempt to load user
      let userLoaded = false;
      function getCurrentUser(auth) {
        return new Promise((resolve, reject) => {
          if (userLoaded) {
            resolve(firebaseAuth().currentUser);
          }
          const unsubscribe = auth.onAuthStateChanged((user) => {
            userLoaded = true;
            unsubscribe();
            resolve(user);
          }, reject);
        });
      }

      currentUser = await getCurrentUser(firebaseAuth());
      if (currentUser) {
        const token = await currentUser.getIdToken();
        return token || null;
      } else {
        return null;
      }
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getServerTimeOffset() {
  try {
    const snapshot = await databaseRef
      .child('/.info/serverTimeOffset')
      .once('value');
    return snapshot.val() || 1; // kalau 0 anggap aja error
  } catch (error) {
    return 1; // biar ada angka nya aja
  }
}

export async function getUserDataandRoles({apotekId}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/auth/role/get-my-role`,
      {},
      {
        headers: {
          'apotek-id': apotekId,
          Authorization: 'Bearer ' + token,
        },
        validateStatus: () => true, // do not throw error on status
      },
    );

    const {userData, roleId, permission} = _.get(
      response,
      'data',
      {},
    );
    // permission masukkan ke ability
    // ability.update(permission);

    console.log({userData, roleId, permission});

    return {
      userData,
      roleId,
      permission,
    };
  } catch (error) {
    return {};
  }
}

export async function logout(apotekId, sessionId) {
  return firebaseAuth()
    .signOut()
    .then(() => true); // kalau 0 anggap aja error
}

export async function signInCustomeUser({
  apotekId,
  username,
  password,
  token,
}) {
  try {
    const response = await axios.post(
      functionUrl + '/public_api/user/login',
      {
        username: username,
        password: password,
        apotek_id: apotekId,
        token: token,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );
    console.log(response);
    // const response = await fetch(
    //   functionUrl + '/public_api/user/login',
    //   data,
    // );
    // return {status: response.status, data: response};
    return {};
  } catch (error) {
    return {error};
  }
}

export async function createApotek({
  apotekName,
  apotekId,
  adminPassword,
}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/public/create-apotek`,
      {
        apotekName,
        apotekId,
        adminPassword,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        validateStatus: () => true, // do not throw error on status
      },
    );

    return {
      data: response.data,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}
export async function checkApotekId({apotekId}) {
  try {
    const token = await getFirebaseIdToken();
    let response = await axios.post(
      `${functionUrl}/public/check-apotek-id`,
      {
        apotekId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        validateStatus: () => true, // do not throw error on status
      },
    );

    return {
      data: response.data,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}
export async function resetPassword({email}) {
  try {
    let response = await axios.post(
      `${functionUrl}/public/reset-password`,
      {
        email,
      },
    );

    return {
      data: response.data,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}

export async function registration(data) {
  try {
    let response = await axios.post(
      `${functionUrl}/public/registration`,
      {
        ...data,
      },
      {},
    );

    return {
      data: response.data,
      status: response.status,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}

export async function getOwnedApotek() {
  try {
    const token = await getFirebaseIdToken();

    let response = await axios.post(
      `${functionUrl}/public/get-owned-apotek`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    return {
      data: response.data,
      status: response.status,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}

export async function toDashboardAsAdmin({apotekId}) {
  try {
    const token = await getFirebaseIdToken();

    let response = await axios.post(
      `${functionUrl}/public/to-dashboard-as-admin`,
      {apotekId},
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    return {
      data: response.data,
      status: response.status,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}
