import React, {Fragment} from 'react';

const Page404 = () => {
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E0"
          />
        </svg>
      </div>
    );
  };
  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-5"
        style={{backgroundColor: '#1166D8'}}>
        {renderWave()}
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-12 col-sm-12 py-5 text-center order-2 order-md-1 ">
              <div>
                <img src="/images/404.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <Fragment>{renderTopContent()}</Fragment>;
};

export default Page404;
