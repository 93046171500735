import {
  all,
  take,
  takeEvery,
  takeLeading,
  put,
  call,
  fork,
  select,
  cancel,
} from 'redux-saga/effects';
import {buffers, eventChannel} from 'redux-saga';

import {
  login,
  loginWithToken,
  logout,
  getServerTimeOffset,
  createApotek,
  getUserDataandRoles,
} from '../../services/user';

import actions from './actions';
import {
  firebaseAuth,
  databaseRef,
  databaseDataRef,
  storageRef,
} from 'services/firebase';
import {history} from 'index';

import {syncChannel} from '../syncChannel';
import Swal from 'sweetalert2';

export const getRef = (rsf, pathOrRef) =>
  typeof pathOrRef === 'string' ? rsf.child(pathOrRef) : pathOrRef;

export function channel(
  pathOrRef,
  event = 'value',
  buffer = buffers.none(),
) {
  // const ref = databaseRef.child(path);
  const ref = getRef(databaseDataRef, pathOrRef);

  const lchannel = eventChannel((emit) => {
    if (process.env.REACT_APP_FIREBASE_FUNCTION_USE_EMULATOR) {
      console.log('listening ', event, 'for path : ', pathOrRef);
    }
    const callback = ref.on(event, (dataSnapshot) =>
      emit({
        snapshot: dataSnapshot,
        key: dataSnapshot.key,
        value: dataSnapshot.val(),
      }),
    );

    // Returns unsubscribe function
    return () => {
      if (process.env.REACT_APP_FIREBASE_FUNCTION_USE_EMULATOR) {
        console.log('unlisten ', event, 'for path : ', pathOrRef);
      }
      ref.off(event, callback);
    };
  }, buffer);

  return lchannel;
}

export function* sync(pathOrRef, options, event) {
  const lchannel = yield call(channel, pathOrRef, event);
  yield fork(syncChannel, lchannel, {
    ...options,
  });
}

// const defaultTransform = (data) => data.value;

function authChannel() {
  const lchannel = eventChannel((emit) => {
    // mestinya cuma ketrigger kalau login atau logout
    // kalau logout user nya null
    // kalau mau ambil jwt nya mending pake onIdTokenChanged, lalu simpan token nya di state
    const unsubscribe = firebaseAuth().onAuthStateChanged(
      (user) => emit({user}),
      (error) => emit({error}),
    );

    return unsubscribe;
  });

  return lchannel;
}

function tokenChannel() {
  const lchannel = eventChannel((emit) => {
    const unsubscribe = firebaseAuth().onIdTokenChanged(
      (user) => emit({user}),
      (error) => emit({error}),
    );

    return unsubscribe;
  });

  return lchannel;
}

export function* LOGIN({payload}) {
  const {email, password, apotekId} = payload;
  console.log(email, password);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      loginError: '',
    },
  });
  // if (target === 'management') {
  const res = yield call(login, email, password);
  console.log('dari redux user', res);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      loginError: res.error || '',
    },
  });
  if (res.status === 'success') {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        selectedApotekId: apotekId,
      },
    });
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: res.error && res.error.message,
    });
  }
}

export function* LOGIN_WITH_TOKEN({payload}) {
  const {token} = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const result = yield call(loginWithToken, token);
  if (result.status === 'success') {
    // TODO: set bugsnag user
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  const apotekId = yield select(
    (state) => state.user && state.user.selectedApotekId,
  );
  const sessionId = yield select(
    (state) => state.session && state.session.key,
  );
  yield put({
    type: 'session/CLOSE_SESSION',
  });

  yield call(logout, apotekId, sessionId);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      token: '',
      email: '',
      avatar: '',
      authorized: false,
      selectedApotekId: '',
      apotekIds: [],
      loading: false,
      apotekInfo: {},
    },
  });
  // notifikasi ke semua listener untuk berhenti
  yield put({
    type: 'user/LOGGED_OUT',
  });
}

const extractApotekIdFromEmail = (email) => {
  const splittedByAt = `${email}`.split('@');
  // ambil elemen terakhir
  if (splittedByAt.length <= 1) {
    return '';
  }
  const emailDomain = splittedByAt[splittedByAt.length - 1];

  if (!emailDomain.endsWith('.apotekdigital.com')) {
    return '';
  }

  return emailDomain.split('.apotekdigital.com')[0];
};

export function* LOAD_CURRENT_ACCOUNT(user) {
  // ini dipanggil ketika user nya sudah logged in, misalnya ketika tab baru dll

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  // console.log('loading current account');
  // ambil apotekId dari email?

  const apotekId = extractApotekIdFromEmail(user.email);

  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: user.uid,
        email: user.email,
        selectedApotekId: apotekId,
        authorized: true,
      },
    });

    const {userData, roleId} = yield call(getUserDataandRoles, {
      apotekId: apotekId,
    });

    yield put({
      type: 'user/SET_STATE',
      payload: {
        userData: userData,
        roleId: roleId,
        // permission: permission,
      },
    });

    const serverTimeOffset = yield call(getServerTimeOffset);

    yield put({
      type: 'user/SET_STATE',
      payload: {
        serverTimeOffset: serverTimeOffset || 1,
      },
    });
  } catch (error) {
    console.error(error);
    // logout aja?
  }
  // jadi kalaupun error, loading nya tetap false

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

function* syncUserSaga() {
  const lchannel = yield call(authChannel);

  console.log('syncUserSaga');

  while (true) {
    try {
      const {error, user} = yield take(lchannel);
      // ambil data user nya di sini?
      // kalau logout sebelum data user nya keambil gimana?
      console.log('saga user : ', user);
      console.log('saga error : ', error);
      // if (user) yield put(syncUser(user));
      // else yield put(syncError(error));
      if (error) {
        console.log('ada error');
        // kalau error
        // hitungannya ke logout?
        yield put({
          type: 'user/LOGGED_OUT',
        });
      } else if (user) {
        // console.log('ada user');
        // kalau log in? kalau user nya ada?
        yield call(LOAD_CURRENT_ACCOUNT, user);
      } else {
        console.log('nggak ada user');

        yield put({
          type: 'user/SET_STATE',
          payload: {
            id: '',
            email: '',
            role: '',
            authorized: false, // kalau nggak ada role nya, balikin ke login
            emailVerified: false,
            loading: false,
          },
        });
        yield put({
          type: 'user/LOGGED_OUT',
        });
      }
    } catch (error) {
      // TODO: handle error nya
    }
  }
}

const defaultTransform = (data) => data.value;

export function* CREATE_APOTEK({payload}) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const result = yield call(createApotek, payload);
  console.log('sudah created apotek', result);
  if (result.isSuccess) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        selectedApotekId: payload.apotek_id,
      },
    });
    // navigation.replace('MainScreen');
    // BootstrapNavigation.navigate('MainScreen');
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export function* LOGGED_OUT({}) {
  // history.push('/login');

  yield put({
    type: 'user/CLEAR_STATE',
  });
  yield put({
    type: 'settings/CLEAR_STATE',
  });
  yield put({
    type: 'warehouses/CLEAR_STATE',
  });
  yield put({
    type: 'session/CLEAR_STATE',
  });
  yield put({
    type: 'sales/CLEAR_STATE',
  });
  yield put({
    type: 'racks/CLEAR_STATE',
  });
  yield put({
    type: 'product/CLEAR_STATE',
  });
  yield put({
    type: 'paymentChannels/CLEAR_STATE',
  });
  yield put({
    type: 'packagingUnits/CLEAR_STATE',
  });
  // yield put({
  //   type: 'deviceInfo/CLEAR_STATE',
  // });
  yield put({
    type: 'contacts/CLEAR_STATE',
  });
}

export function* PROFILE_CHANGE_DATA({payload}) {
  const userId = yield select((state) => state.user && state.user.id);
  if (userId) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileLoading: true,
      },
    });
    if (payload.name) {
      console.log('changing name', payload.name);

      yield call(async () => {
        await databaseRef
          .child(`/users/${userId}/name`)
          .set(payload.name);
      });
    }
    if (payload.newProfpic) {
      const filename = `${databaseRef.push().key}.${getFileExtension(
        payload.newProfpic.filename,
      )}`;

      yield call(async () => {
        const uploadPromise = new Promise((resolve, reject) => {
          const uploadtask = storageRef
            .child(`/user-uploads/${userId}/${filename}`)
            .putString(payload.newProfpic.data, 'base64');
          uploadtask.on(
            'state_changed',
            (data) => {
              console.log(
                'sedang upload : ',
                Math.ceil(
                  (data.bytesTransferred / data.totalBytes) * 100,
                ),
              );
            },
            (error) => {
              console.log('upload gagal', error);
              reject(error);
            },
            (data) => {
              console.log('upload selesai', data);
              resolve(data);
            },
          );
        });
        await uploadPromise;
      });

      const newUrl = yield call(async () =>
        storageRef
          .child(`/user-uploads/${userId}/${filename}`)
          .getDownloadURL(),
      );

      yield call(async () => {
        await databaseRef
          .child(`/users/${userId}/photoURL`)
          .set(newUrl);
      });
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profileLoading: false,
      },
    });
  }
}
const getFileExtension = (filename) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext === null ? '' : ext[1];
};

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN_WITH_TOKEN, LOGIN_WITH_TOKEN),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),

    // takeLeading(actions.LISTEN_USER_DATA, LISTEN_USER_DATA),
    takeEvery(actions.PROFILE_CHANGE_DATA, PROFILE_CHANGE_DATA),
    // takeEvery(actions.UPDATE_TOKEN, UPDATE_TOKEN),
    takeEvery(actions.CREATE_APOTEK, CREATE_APOTEK),
    takeEvery(actions.LOGGED_OUT, LOGGED_OUT),

    syncUserSaga(),
    // syncTokenSaga(),
  ]);
}
