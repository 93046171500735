import React, {Fragment} from 'react';
import {Link, useParams} from 'react-router-dom';

const TermsAndConditions = () => {
  const lastUpdatedAt = '1 November 2021';
  const renderWave = () => {
    return (
      <div
        style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <svg
          fill="none"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m0 0 48 26.7c48 26.3 144 80.3 240 85.3s192-37 288-16 192 107 288 106.7c96 0.3 192-85.7 288-90.7s192 69 240 106.7l48 37.3v64h-48-240-288-288-288-240-48v-320z"
            fill="#0E70E022"
          />
        </svg>
      </div>
    );
  };

  const renderTopContent = () => {
    return (
      <div
        className="position-relative overflow-hidden py-5"
        style={{backgroundColor: 'aliceblue'}}>
        {renderWave()}
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-12 col-sm-12 py-5 text-md-left text-center order-2 order-md-1">
              <h1 className=" font-weight-bold text-center">
                Syarat & Ketentuan
              </h1>
              <p className="text-center" style={{opacity: 0.8}}>
                Update Terakhir: {lastUpdatedAt}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSecondClosing = () => {
    return (
      <div
        style={{
          // backgroundColor: '#FAFAFD',
          position: 'relative',
          paddingTop: 30,
        }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            height: '50%',
            bottom: 0,
          }}
        />
        <div className="container">
          <div className="card px-5">
            <div className="row text-md-left text-center py-4">
              <div className="col-md-8">
                <h2>
                  Saatnya investasi pada teknologi untuk meningkatkan
                  bisnis apotekmu!
                </h2>
              </div>
              <div className="col-md-4">
                <p
                  style={{
                    color: '#1A4072',
                    opacity: 0.8,
                    fontSize: 18,
                  }}>
                  Yuk, mulai dulu aja dengan paket gratis hingga
                  kapanpun!
                </p>
                <Link to="/register" className="btn btn-blue">
                  Mulai Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderTopContent()}
      <div
        style={
          {
            // backgroundColor: '#FAFAFD',
          }
        }>
        <div
          className="container pt-5"
          style={{minHeight: 300, maxWidth: 800}}>
          <div style={{position: 'relative'}}>
            <br />
            <h5>
              Syarat dan ketentuan yang terdapat pada halaman ini
              bersifat mengikat dan berlaku ketika Anda mulai
              menggunakan layanan Apotek Digital, yaitu ketika
              mendaftarkan diri pada form pendaftaran.{' '}
            </h5>
            <p>
              Pihak Apotek Digital berhak untuk mengubah syarat dan
              ketentuan ini kapan saja dan mulai berlaku sejak
              terlampir di website resmi apotekdigital.com. Karena ada
              kemungkinan bahwa Syarat & Ketentuan ini akan berubah,
              maka menjadi kewajiban Anda untuk memastikan bahwa Anda
              sudah membaca, mengerti, dan menyetujui Syarat &
              Ketentuan terbaru yang tersedia pada website Apotek
              Digital. Adapun untuk perubahan yang sifatnya mayor,
              kami akan menginfokan kepada pengguna aktif Apotek
              Digital.
            </p>
            <p>
              Syarat & Ketentuan ini terakhir di perbarui pada tanggal{' '}
              {lastUpdatedAt}
            </p>
            <ol>
              <li id="definisi">
                <h4>Istilah dan Definisi</h4>
                <ol>
                  <li style={{lineHeight: 1.5}}>
                    <strong>Perjanjian</strong> – berarti Syarat &
                    Ketentuan ini;
                  </li>
                  <li style={{lineHeight: 1.5}}>
                    <strong>Biaya Akses</strong> – berarti biaya
                    bulanan yang harus Anda bayar sesuai daftar biaya
                    yang dicantumkan pada Website Apotek Digital (yang
                    Apotek Digital dapat merubah dari waktu ke waktu
                    dengan sepengetahuan Anda);
                  </li>
                  <li>
                    <strong>Informasi Rahasia</strong> – melingkup
                    semua informasi yang dikomunikasikan antara pihak
                    Perjanjian ini, baik secara tertulis, elektronik,
                    atau lisan, termasuk Layanan ini, tapi tidak
                    termasuk informasi yang sudah menjadi atau akan
                    dijadikan untuk public, terkecuali yang sudah
                    diungkapkan dan terbongkar tanpa hak atau oleh
                    pihak pengguna atau lainnya secara tidak sah;
                  </li>
                  <li>
                    <strong>Data</strong> – berarti data apapun yang
                    Anda masukan atau dimasukan dengan kewenangan Anda
                    kepada Website;
                  </li>
                  <li>
                    <strong>Hak Kekayaan Intelektual</strong> –
                    berarti paten, merek dagang, merek jasa atau
                    layanan, hak cipta, hak pada disain, pengetahuan,
                    atau hak kekayaan intelektual atau industri
                    lainnya, maupun terdaftar atau tidak terdaftar;
                  </li>
                  <li>
                    <strong>Layanan</strong> – berarti layanan berupa
                    software dengan segala fiturnya yang disediakan
                    (dan dapat dirubah atau diperbarui dari waktu ke
                    waktu) melalui Website maupun aplikasi mobile;
                  </li>
                  <li>
                    <strong>Website</strong> – berarti situs internet
                    di domain{' '}
                    <a
                      href="https://apotekdigital.com"
                      target="_blank">
                      apotekdigital.com
                    </a>
                    ,{' '}
                    <a
                      href="https://apotekdigital.com"
                      target="_blank">
                      app.apotekdigital.com
                    </a>{' '}
                    atau situs internet lainnya yang di kelola oleh
                    Apotek Digital;
                  </li>
                  <li>
                    <strong>Apotek Digital</strong> – berarti PT
                    Teknologi Kesehatan Indonesia yang terdaftar di
                    Indonesia dengan nomor TDP AHU-00140.AH.02.02;
                  </li>
                  <li>
                    <strong>Pengguna Diundang</strong> – berarti
                    setiap orang atau badan, selain Pelanggan, yang
                    memakai Layanan dari waktu ke waktu dengan izin
                    dari Pelanggan;
                  </li>
                  <li>
                    <strong>Pelanggan</strong> - berarti orang, maupun
                    atas nama pribadi, organisasi atau badan lainnya,
                    yang mendaftar untuk menggunakan Layanan;
                  </li>
                  <li>
                    <strong>Anda</strong> - berarti Pelanggan,
                    Pengguna atau Pengguna Diundang.
                  </li>
                </ol>
              </li>
              <li id="penggunaan-software">
                <h3>Penggunaan Software</h3>
                <p>
                  Apotek Digital memberi Anda hak untuk mengakses dan
                  memakai Layanan Apotek Digital melalui Website kami
                  dengan peran penggunaan yang sudah ditentukan untuk
                  Anda, sesuai dengan jenis layanan yang Anda pilih.
                  Hak ini non-eksklusif, tidak dapat
                  dipindahtangankan, dan dibatasi oleh dan bergantung
                  pada perjanjian ini. Anda mengakui dan menyutujui,
                  bergantung kepada perjanjian tulis apapun yang
                  berlaku antara Pelanggan dan Pengguna Diundang, atau
                  hukum lainnya yang berlaku:
                </p>
                <ol>
                  <li>
                    bahwa adalah tanggung jawab Pelanggan untuk
                    menentukan siapa yang mendapat akses sebagai
                    Pengguna Diundang dan jenis peran dan hak yang
                    mereka punya untuk mengakses jenis data yang Anda
                    miliki;
                  </li>
                  <li>
                    bahwa tanggung jawab Pelanggan untuk semua
                    penggunaan Layanan oleh Pengguna Diundang;
                  </li>
                  <li>
                    bahwa tanggung jawab Pelanggan untuk mengendalikan
                    setiap tingkat akses untuk Pengguna Diundang
                    kepada organisasi dan Layanan yang relevan setiap
                    saat, dan bisa menarik atau mengubah akses atau
                    tingkat akses Pengguna Diundang kapanpun, untuk
                    alasan apapun di dalam kasus manapun;
                  </li>
                  <li>
                    jika ada perselisihan antara Pelanggan dan
                    Pengguna Diundang mengenai akses kepada organisasi
                    atau Layanan apapun, Pelanggan lah yang harus
                    membuat keputusan dan mengatur akses atau tingkat
                    akses ke Data atau Layanan yang Pengguna Diundang
                    akan dapat, jika ada.
                  </li>
                </ol>
              </li>
              <li class="about-list" id="ketersediaan-layanan">
                <h4>Ketersediaan Pelayanan Kami</h4>
                <ol>
                  <li>
                    <h5>Layanan:</h5>
                    <p>
                      Layanan kami memiliki jaminan 90% uptime minimal
                      perbulan.
                    </p>
                    <p>
                      Jika layanan kami jatuh di bawah 90% uptime,
                      maka pelanggan kami berhak mengajukan laporan
                      dan mendapatkan kompensasi.
                    </p>
                  </li>
                  <li>
                    <h5>Product Support:</h5>
                    <p>
                      Pelayanan Product Support kami tersedia melalui
                      3 media:
                    </p>
                    <ul>
                      <li>
                        Chat: 9.00 – 18.00, Hari Senin – Jumat
                        <div>
                          Chat yang diterima di luar jam kerja akan
                          dibalas melalui email dalam kurun waktu 24
                          jam.
                        </div>
                      </li>
                      <li>
                        Telepon: 9.00 – 18.00, Hari Senin – Jumat
                      </li>
                      <li>
                        Email: 9.00 – 18.00, Hari Senin – Jumat
                        <div>
                          Email yang diterima di luar jam kerja akan
                          dibalas dalam kurun waktu 24 jam.
                        </div>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li class="about-list" id="kewajiban-anda">
                <h4>Kewajiban Anda</h4>
                <ol>
                  <li>
                    <h5>Kewajiban Pembayaran</h5>
                    <p>
                      Sebuah tagihan untuk Biaya Akses akan di buat
                      setiap bulan, mulai satu bulan dari tanggal Anda
                      mulai berlangganan untuk Layanan Apotek Digital.
                      Semua tagihan akan mengandung Biaya Akses untuk
                      periode Masa Aktif pemakaian Layanan. Apotek
                      Digital akan terus membuat tagihan untuk Anda
                      setiap akhir periode Masa Aktif sampai
                      perjanjian ini diakhiri sesuai dengan ketentuan
                      pada Pasal 9.
                      <br />
                      Masa Aktif otomatis akan dimulai paling lambat 3
                      hari setelah Anda melakukan pembayaran Biaya
                      Akses untuk pertama kalinya.
                    </p>
                    <p>
                      Semua tagihan dari Apotek Digital akan dikirim
                      kepada Anda, atau kepada kontak penagihan yang
                      telah Anda berikan, melalui email. Anda harus
                      membuat pembayaran untuk seluruh nilai yang
                      tertera di tagihan Anda sebelum melewati tanggal
                      jatuh tempo untuk tagihannya, yang harus
                      dilunaskan 7 hari dari saat tagihan dikirim oleh
                      Apotek Digital. Anda mempunyai tanggung jawab
                      untuk pembayaran semua pajak dan bea yang
                      ditambahkan kepada Biaya Akses tersebut. Anda
                      juga diminta untuk menyimpan bukti transaksi.
                      Jika layanan sempat terhenti sebelum pembayaran
                      dilakukan, kami akan mengaktifkan layanan
                      setelah Anda melakukan pembayaran Biaya Akses
                      untuk perpanjangan Masa Aktif.
                    </p>
                  </li>
                  <li>
                    <h5>Kewajiban Umum</h5>
                    <p>
                      Anda harus memastikan hanya memakai Layanan dan
                      Website untuk keperluan internal bisnis Anda
                      yang benar dan secara sah, dengan Syarat dan
                      Ketentuan dan pemberitahuan yang diumumkan oleh
                      Apotek Digital atau ketentuan yang tercantum di
                      Website. Anda boleh memakai Layanan dan Website
                      atas nama orang atau badan lain, atau untuk
                      memberi layanan kepada mereka, tetapi Anda harus
                      memastikan bahwa Anda mempunyai wewenang untuk
                      melakukannya, dan semua pihak yang menerima
                      Layanan melalui Anda memenuhi dan menyetujui
                      semua syarat dalam Perjanjian ini yang berlaku
                      kepada Anda;
                    </p>
                  </li>
                  <li>
                    <h5>Syarat Akses</h5>
                    <p>
                      Anda harus memastikan bahwa semua username dan
                      password yang diperlukan untuk mengakses Layanan
                      Apotek Digital tersimpan dengan aman dan secara
                      rahasia. Anda harus segera memberitahu Apotek
                      Digital mengenai pemakaian password Anda dari
                      pihak yang tidak berwenang, atau pelanggaran
                      keamanan lainnya, dan Apotek Digital akan reset
                      password Anda, dan Anda harus melakukan semua
                      tindakan lainnya yang dianggap cukup penting
                      oleh Apotek Digital untuk mempertahankan atau
                      meningkatkan keamanan sistem computer dan
                      jaringan Apotek Digital, dan akses Anda kepada
                      Layanan kami.
                    </p>
                    <p>
                      Sebagai syarat dari Ketentuan-ketentuan ini,
                      saat mengakses dan menggunakan Layanan Apotek
                      Digital, Anda harus:
                    </p>
                    <ul>
                      <li>
                        Tidak mencoba untuk melemahkan keamanan atau
                        integritas dari sistem komputer atau jaringan
                        Apotek Digital, atau jika Layanan-nya di{' '}
                        <em>host</em> oleh pihak ketiga, sistem
                        komputer atau jaringan pihak ketiga itu;
                      </li>
                      <li>
                        Tidak menggunakan atau menyalahgunakan Layanan
                        Apotek Digital dengan cara apapun yang dapat
                        mengganggu kemampuan pengguna lain untuk
                        menggunakan Layanan atau Website;
                      </li>
                      <li>
                        Tidak mencoba untuk mendapatkan akses yang
                        tidak sah kepada materi apapun selain yang
                        sudah dinyatakan dengan jelas bahwa Anda telah
                        mendapatkan izin untuk mengakses-nya, atau
                        untuk mengakses sistem komputer kami dimana
                        Layanan-nya di <em>host</em>;
                      </li>
                      <li>
                        Tidak mengirimkan, atau memasukan kedalam
                        Website: file apapun yang dapat merusak alat
                        komputer atau <em>software</em> orang lain,
                        bahan-bahan yang menghina, atau materi atau
                        Data yang melanggar hukum apapun (termasuk
                        Data atau materi lainnya yang terlindungi oleh
                        hak cipta atau rahasia dagang dimana Anda
                        tidak memiliki hak untuk memakainya);
                      </li>
                      <li>
                        Tidak mencoba untuk mengubah, menyalin,
                        meniru, membongkar, atau melakukan reverse
                        engineering untuk program komputer apapun yang
                        dipakai untuk memberi Layanan Apotek Digital,
                        atau untuk menggunakan Website diluar
                        penggunaan yang diperlukan dan dimaksudkan.
                      </li>
                    </ul>
                    <p></p>
                  </li>
                  <li>
                    <h5>Batasan Penggunaan</h5>
                    <p>
                      Penggunaan Layanan Apotek Digital mungkin dapat
                      dibatasi melingkup, tapi tidak terbatas kepada,
                      volume transaksi bulanan dan jumlah panggilan
                      yang Anda diizinkan untuk gunakan dengan
                      memanggil kepada programming interface aplikasi
                      Apotek Digital. Bila ada, batasan-batasan
                      tersebut akan ditentukan dan dicantumkan pada
                      Layanan yang terkait.
                    </p>
                  </li>
                  <li>
                    <h5>Syarat Komunikasi</h5>
                    <p>
                      Sebagai syarat pada Ketentuan ini, jika Anda
                      memakai alat komunikasi apapun yang tersedia
                      melalui Website (seperti <em>forum</em> apapun
                      atau ruang <em>chat</em>), Anda setuju untuk
                      memakai alat-alat komunikasi tersebut hanya
                      untuk tujuan yang sah. Anda tidak boleh memakai
                      alat-alat komunikasi tersebut untuk memasang
                      atau menyebarkan materi apapun yang tidak
                      terkait dengan pemakaian Layanan-nya, termasuk
                      (tapi tidak terbatas dengan): menawarkan barang
                      dan jasa untuk dijual, <em>e-mail</em> komersial
                      yang tidak diminta atau diinginkan, file yang
                      dapat merusak alat komputer atau software orang
                      lain, bahan-bahan yang mungkin dapat menghina
                      pengguna Layanan atau Website yang lain, atau
                      materi yang melanggar hukum apapun (termasuk
                      materi yang terlindungi oleh hak cipta atau
                      rahasia dagang dimana Anda tidak memiliki hak
                      untuk memakainya).
                    </p>
                    <p>
                      Saat Anda melakukan komunikasi dalam bentuk
                      apapun di Website, Anda menjamin bahwa Anda
                      diperbolehkan untuk membuat komunikasi tersebut.
                      Apotek Digital tidak berkewajiban untuk
                      memastikan bahwa komunikasi pada Website adalah
                      sah dan benar, atau bahwa mereka terkait hanya
                      pada penggunaan Layanan. Apotek Digital berhak
                      untuk menghapus komunikasi apapun setiap saat
                      atas kebijakannya sendiri.
                    </p>
                  </li>
                  <li>
                    <h5>Tanggung Jawab Ganti Rugi</h5>
                    <p>
                      Anda membebaskan Apotek Digital dari semua:
                      tuntutan, gugatan, biaya kerugian, kerusakan,
                      dan kehilangan yang timbul sebagai hasil dari
                      pelanggaran Anda kepada Syarat dan Ketentuan
                      yang tertera di Perjanjian ini, atau kewajiban
                      apapun yang mungkin Anda punya kepada Apotek
                      Digital, termasuk (tapi tidak terbatas kepada)
                      biaya apapun yang berkaitan kepada perolehan
                      Biaya Akses apapun yang sudah jatuh tempo tetapi
                      belum Anda bayar.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="about-list" id="kerahasian-privasi">
                <h4>Kerahasiaan dan Privasi</h4>
                <ol>
                  <li>
                    <h5>Kerahasiaan</h5>
                    <p>
                      Masing-masing pihak berjanji untuk menjaga
                      kerahasiaan semua Informasi Rahasia pihak
                      lainnya sehubungan dengan ketentuan ini. Setiap
                      pihak TIDAK AKAN, tanpa persetujuan tertulis
                      dari pihak yang lain, mengungkapkan atau memberi
                      Informasi Rahasia kepada siapapun, atau
                      menggunakannya untuk kepentingan sendiri, selain
                      sebagaimana dimaksud oleh Ketentuan ini.
                    </p>
                    <p>
                      Kewajiban masing-masing pihak dalam ketentuan
                      ini akan bertahan walaupun setelah pemutusan
                      Ketentuan ini.
                    </p>
                    <p>
                      Ketentuan-ketentuan pasal tidak berlaku untuk
                      informasi yang:
                    </p>
                    <ul>
                      <li>
                        Telah menjadi pengetahuan umum selain karena
                        pelanggaran ketentuan ini;
                      </li>
                      <li>
                        Diterima dari pihak ketiga yang dengan secara
                        sah memperolehnya, dan tidak mempunyai
                        kewajiban untuk membatasi pengungkapannya;
                      </li>
                      <li>
                        Dikembangkan dengan sendiri tanpa akses kepada
                        Informasi Rahasia.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h5>Privasi</h5>
                    <p>
                      Apotek Digital memiliki dan mempertahankan
                      kebijakan privasi yang menjelaskan dan
                      menetapkan kewajiban para pihak untuk
                      menghormati informasi pribadi. Anda disarankan
                      membaca kebijakan privasi kami di
                      apotekdigital.com/privacy, dan Anda akan
                      dianggap sudah menyetujui kebijakan itu saat
                      Anda menyetujui ketentuan ini.
                    </p>
                  </li>
                </ol>
              </li>
              <li class="about-list" id="intelectual-property">
                <h4>Hak Kekayaan Intelektual</h4>
                <p>
                  Kepemilikan dan semua Hak Kekayaan Intelektual yang
                  didapat pada Layanan, Website, dan dokumentasi
                  apapun yang terkait dengan Layanan tetap menjadi hak
                  milik Apotek Digital.
                </p>
                <p>
                  Kepemilikan dan semua Hak Kekayaan Intelektual yang
                  terdapat di Data tetap menjadi hak milik Anda. Akan
                  tetapi, akses kepada Data Anda bergantung pada
                  pelunasan Biaya Akses Apotek Digital saat tagihan
                  jatuh tempo. Anda memberi izin kepada Apotek Digital
                  untuk memakai, menyalin, mengirim, menyimpan, dan
                  melakukan back-up untuk informasi dan Data Anda
                  dengan maksud dan tujuan memberi akses kepada Anda
                  dan agar dapat menggunakan Layanan Apotek Digital,
                  atau untuk tujuan lainnya yang terkait dengan
                  penyediaan layanan kami untuk Anda.
                </p>
                <p>
                  Anda sangat disarankan untuk menyimpan salinan untuk
                  semua Data yang Anda masukan ke dalam Layanan Apotek
                  Digital. Apotek Digital mematuhi kebijakan dan
                  menjalani prosedur terbaik untuk mencegah kehilangan
                  data, termasuk rutinitas sistem harian untuk back-up
                  data, tetapi tidak membuat jaminan apapun bahwa
                  tidak akan pernah adanya kehilangan Data. Apotek
                  Digital dengan jelas mengesampingkan tanggung jawab
                  untuk setiap kehilangan Data dengan sebab apapun.
                </p>
              </li>
              <li class="about-list" id="jaminan-pengakuan">
                <h4>Jaminan dan Pengakuan</h4>
                <ol>
                  <li>
                    Anda menjamin bahwa, jika Anda mendaftar untuk
                    menggunakan Layanan atas nama orang lain, Anda
                    memiliki wewenang untuk menyetujui
                    Ketentuan-ketentuan ini atas nama orang tersebut,
                    dan menyetujui bahwa dengan mendaftar untuk
                    memakai Layanan Apotek Digital, Anda mengikat
                    orang yang Anda atas namakan untuk, atau dengan
                    niat untuk, membuat tindakan atas nama mereka
                    kepada setiap kewajiban manapun yang Anda telah
                    setujui pada Ketentuan-ketentuan ini, tanpa
                    membatasi kewajiban Anda sendiri kepada
                    ketentuannya.
                  </li>
                  <li>
                    Anda mengakui bahwa:
                    <ol>
                      <li>
                        <p>
                          Anda memiliki wewenang untuk menggunakan
                          Layanan dan Website, dan untuk mengakses
                          informasi dan Data yang Anda masukan kedalam
                          Website, termasuk informasi atau Data apapun
                          yang dimasukan kedalam Website oleh siapapun
                          yang Anda telah beri kewenangan untuk
                          menggunakan Layanan Apotek Digital.
                        </p>
                        <p>
                          Anda juga berwenang untuk mengakses
                          informasi dan data yang sudah di proses,
                          yang disediakan untuk Anda melalui
                          penggunaan Anda pada Website dan Layanan
                          kami (maupun informasi dan Data itu Anda
                          miliki sendiri atau milik orang lain).
                        </p>
                      </li>
                      <li>
                        <p>
                          Apotek Digital tidak bertanggung jawab
                          kepada siapapun selain Anda, dan tidak ada
                          maksud apapun dalam Perjanjian ini untuk
                          memberi manfaat kepada siapapun selain Anda.
                          Jika Anda memakai Layanan atau mengakses
                          Website atas nama atau untuk manfaat
                          seseorang selain Anda (maupun organisasi
                          berbadan hukum atau tidak, atau lainnya),
                          Anda menyutujui bahwa:
                        </p>
                        <ul>
                          <li>
                            Anda bertanggung jawab untuk memastikan
                            bahwa Anda memiliki hak untuk
                            melakukannya;
                          </li>
                          <li>
                            Anda bertanggung jawab atas memberi
                            wewenang kepada siapapun yang Anda berikan
                            akses kepada informasi atau Data, dan Anda
                            menyutujui bahwa Apotek Digital tidak
                            memiliki tanggung jawab untuk menyediakan
                            siapapun akses kepada informasi atau Data
                            tersebut tanpa otorisasi Anda, dan boleh
                            menunjukan permohonan apapun untuk
                            mendapatkan informasi kepada Anda untuk di
                            layani;
                          </li>
                          <li>
                            Anda membebaskan Apotek Digital dari klaim
                            apapun atau kehilangan yang terkait pada:
                            Penolakan Apotek Digital untuk menyediakan
                            akses pada siapapun kepada informasi atau
                            Data Anda sesuai dengan ketentuan ini;
                            Penyediaan informasi atau Data oleh Apotek
                            Digital kepada siapapun berdasarkan
                            otorisasi Anda.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Penyediaan, akses kepada, dan pemakaian
                          Layanan Apotek Digital tersedia sebagaimana
                          adanya dan pada resiko Anda sendiri.
                        </p>
                      </li>
                      <li>
                        <p>
                          Apotek Digital tidak menjamin bahwa
                          penggunaan Layanan tidak akan pernah
                          terganggu atau bebas dari kesalahan. Di
                          antara lain, operasi dan ketersediaan sistem
                          yang digunakan untuk mengakses Layanan,
                          termasuk layanan telpon umum, jaringan
                          komputer dan internet, bisa susah diprediksi
                          dan mungkin bisa dari waktu ke waktu
                          menggangu atau mencegah akses kepada
                          Layanan. Apotek Digital dengan bagaimanapun
                          tidak bertanggung jawab atas gangguan
                          tersebut, atau pencegahan akses kepada
                          penggunaan Layanan.
                        </p>
                      </li>
                      <li>
                        <p>
                          Untuk menentukan bahwa Layanan kami memenuhi
                          keperluan bisnis Anda dan bisa digunakan
                          sesuai dengan tujuan adalah tanggung jawab
                          Anda sendiri.
                        </p>
                      </li>
                      <li>
                        <p>
                          Anda tetap memiliki tanggung jawab untuk
                          mematuhi semua undang-undang yang berlaku.
                          Menjadi tanggung jawab Anda untuk memeriksa
                          bahwa penyimpanan dan akses kepada Data Anda
                          melalui Layanan dan Website tetap mematuhi
                          undang-undang yang berlaku kepada Anda
                          (termasuk undang-undang apapun yang
                          membutuhkan Anda untuk menyimpan arsip).
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Apotek Digital tidak memberi jaminan untuk
                    Layanan-nya. Tanpa mengabaikan ketentuan di atas,
                    Apotek Digital tidak menjamin bahwa Layanan kami
                    akan memenuhi keperluan Anda, atau bahwa akan
                    sesuai untuk tujuan yang Anda niatkan. Untuk
                    menghindari keraguan, semua ketentuan atau jaminan
                    yang bisa diartikan dikecualikan sejauh yang
                    diijinkan oleh hukum, termasuk (tanpa batasan)
                    jaminan penjualan, kesesuaian untuk tujuan, dan
                    tanpa pelanggaran.
                  </li>
                  <li>
                    Anda menjamin dan menunjukkan bahwa Anda sedang
                    memperoleh hak untuk mengakses dan menggunakan
                    Layanan untuk tujuan bisnis dan bahwa, sampai
                    batas maksimum yang diizinkan oleh hukum, jaminan
                    konsumen berdasarkan hukum atau undang-undang yang
                    dimaksudkan untuk melindungi konsumen non-bisnis
                    di yurisdiksi manapun tidak berlaku untuk
                    penyediaan Layanan, Website, atau ketentuan ini.
                  </li>
                </ol>
              </li>
              <li class="about-list" id="batasan-kewajiban">
                <h4>Batasan Kewajiban:</h4>
                <ol>
                  <li>
                    Sampai batas maksimal yang diizinkan oleh hukum,
                    Apotek Digital mengecualikan semua kewajiban dan
                    tanggung jawab kepada Anda (atau orang lain
                    manapun) dalam kontrak, gugatan kesalahan(termasuk
                    kelalaian), atau selainnya, untuk kehilangan
                    apapun (termasuk kehilangan informasi, Data,
                    keuntungan, dan tabungan) atau kerusakan yang
                    diakibatkan, secara langsung atau tidak langsung,
                    dari penggunaan apapun, atau ketergantungan
                    kepada, Layanan atau Website.
                  </li>
                  <li>
                    Jika Anda mengalami kerugian atau kerusakan apapun
                    karena kelalaian atau kegagalan Apotek Digital
                    untuk memenuhi ketentuan ini, klaim apapun dari
                    Anda kepada Apotek Digital yang timbul dari
                    kelalaian atau kegagalan Apotek Digital akan
                    dibataskan mengenai satu kejadian, atau
                    serangkaian kejadian yang terhubung, kepada Biaya
                    Akses yang sudah Anda lunaskan dalam 12 bulan
                    sebelumnya.
                  </li>
                  <li>
                    Jika Anda tidak puas dengan Layanan-nya, jalur
                    penyelesaian tunggal dan eksklusif Anda adalah
                    untuk menhentikan ketentuan ini sesuai dengan
                    pasal 9.
                  </li>
                </ol>
              </li>
              <li class="about-list" id="pemutusan-kontrak">
                <h4>Pemutusan Kontrak:</h4>
                <ol>
                  <li>
                    <h5>Kebijakan Masa Percobaan:</h5>
                    <p>
                      Saat Anda pertama mendaftar untuk mengakses
                      Layanan, Anda bisa mengevaluasi Layanan-nya
                      dengan ketentuan untuk masa percobaan yang sudah
                      di tentukan, tanpa kewajiban untuk melanjutkan
                      penggunaan Layanan-nya. Jika Anda memilih untuk
                      melanjutkan penggunaan Layanan setelah masa
                      percobaan selesai, Anda akan mulai ditagih dari
                      hari sama dimana Anda memasuki informasi untuk
                      penagihan Anda. Jika Anda memilih untuk berhenti
                      menggunakan Layanan-nya, Anda bisa menghapus
                      organisasi Anda.
                    </p>
                  </li>
                  <li>
                    Apotek Digital tidak menyediakan pengembalian uang
                    untuk periode prabayar yang tersisa pada langganan
                    Biaya Akses Anda.
                  </li>
                  <li>
                    Ketentuan ini akan berlaku untuk periode yang
                    dicakup oleh Biaya Akses yang telah atau dapat
                    dibayar pada pasal 3 ayat 1. Pada masa akhir
                    setiap periode penagihan, Ketentuan ini akan
                    berlanjut secara otomatis untuk periode
                    selanjutnya untuk jangka watu yang sama, asalkan
                    Anda terus membayar Biaya Akses yang sudah
                    ditentukan saat jatuh tempo, kecuali salah satu
                    pihak mengakhiri Ketentuan ini dengan
                    pemberitahuan kepada pihak lainnya setidaknya 30
                    hari sebelum akhir periode pembayaran yang
                    bersangkutan.
                  </li>
                  <li>
                    Pelanggaran
                    <ol>
                      <li>
                        Jika Anda melanggar apapun dari Ketentuan ini
                        (termasuk, tapi tidak terbatas dengan, tidak
                        membayar Biaya Akses apapun) dan tidak
                        menyelesaikan masalah pelanggaran dalam 14
                        hari setelah menerima pemberitahuan
                        pelanggaran tersebut jika permasalahan
                        pelanggarang tersebut bisa di selesaikan;
                      </li>
                      <li>
                        Jika Anda melanggar apapun dari Ketentuan ini
                        dan pelanggaran itu tidak bisa di selesaikan
                        (termasuk, tapi tidak terbatas dengan)
                        pelanggaran apapun mengenai pasal 3 ayat 4
                        atau kegagalan untuk membayar Biaya Akses yang
                        sudah melewati tanggal jatuh tempo lebih dari
                        30 hari;
                      </li>
                      <li>
                        Jika Anda atau bisnis Anda bangkrut, atau
                        sedang melewati proses untuk mengakhiri
                        keberadaan organisasi, Apotek Digital bisa
                        mengambil salah satu atau semua tindakan
                        berikut:
                        <ul>
                          <li>
                            Mengakhiri Perjanjian ini dan penggunaan
                            Anda untuk Layanan dan Website kami;
                          </li>
                          <li>
                            Menunda akses Anda kepada Layanan dan
                            Website Apotek Digital untuk periode yang
                            tidak menentu;
                          </li>
                          <li>
                            Menunda akses atau mengakhiri akses kepada
                            semua Data atau Data apapun;
                          </li>
                        </ul>
                      </li>
                      <li>
                        Pemutusan Ketentuan ini tidak mengurangi hak
                        dan kewajiban para pihak yang masih harus
                        dibayar sampai dengan tanggal terminasi. Pada
                        pengakhiran Perjanjian ini Anda akan tetap
                        menanggung biaya yang masih harus dibayar dan
                        jumlah yang jatuh tempo untuk pembayaran
                        sebelum atau setelah pengakhiran, dan segera
                        berhenti menggunakan Layanan dan Website kami.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li class="about-list" id="ketentuan-umum-lainnya">
                <h4>Ketentuan Umum Lainnya</h4>
                <ol>
                  <li>
                    Syarat dan Ketentuan ini, bersama dengan Kebijakan
                    Privasi Apotek Digital dan ketentuan dari
                    pemberitahuan atau instruksi yang diberikan kepada
                    Anda dibawah Syarat dan Metentuan ini menggantikan
                    dan menghapus semua perjanjian sebelumnya,
                    representasi (baik lisan maupun tulisan), dan
                    pemahaman, dan merupakan keseluruhan perjanjian
                    antara Anda dan Apotek Digital yang berhubungan
                    dengan Layanan dan hal lainnya yang dibahas dalam
                    Ketentuan ini.
                  </li>
                  <li>
                    Jika salah satu pihak melepaskan tuntutan dari
                    pelanggaran apapun pada Ketentuan ini, ini tidak
                    akan melepaskan mereka dari tuntutan pelanggaran
                    lainnya. Pelepasan dari tuntuntan tidak efektif
                    kecuali dibuat secara tulisan.
                  </li>
                  <li>
                    Para pihak tidak harus bertanggung jawab atas
                    keterlambatan atau kegagalan dalam untuk
                    menyelesaikan kewajibannya dibawah Ketentuan ini
                    jika keterlambatan atau kegagalannya adalah karena
                    sebab apapun yang di luar kendali. Ayat ini tidak
                    berlaku untuk kewajiban pembayaran uang apapun.
                  </li>
                  <li>
                    Anda tidak dapat mengalihkan atau mentransfer hak
                    kepada orang lain tanpa persetujuan tertulis dari
                    Apotek Digital.
                  </li>
                  <li>
                    Apabila terjadi perselisihan antara kedua belah
                    pihak, akan coba diselesaikan secara musyawarah
                    terlebih dahulu untuk mencapai mufakat. Apabila
                    dengan cara tersebut tidak tercapai kata sepakat,
                    maka kedua belah pihak sepakat untuk menyelesaikan
                    permasalahan tersebut dilakukan melalui prosedur
                    hukum dengan memilih kedudukan hukum Republik
                    Indonesia di Kantor Pengadilan Negeri Jakarta
                    Pusat.
                  </li>
                  <li>
                    Setiap pemberitahuan yang diberikan berdasarkan
                    Persyaratan ini oleh satu pihak ke yang lain harus
                    dilakukan secara tertulis melalui email dan akan
                    dianggap telah diberikan pada saat transmisi
                    dilakukan. Pemberitahuan kepada Apotek Digital
                    harus dikirim ke support@apotekdigital.com atau ke
                    alamat email lainnya yang diberitahukan kepada
                    Anda oleh Apotek Digital. Pemberitahuan kepada
                    Anda akan dikirim ke alamat email yang Anda
                    berikan saat membuat akses Anda pada Layanan kami.
                  </li>
                  <li>
                    Subscription adalah pembayaran berulang yang
                    dibayar di muka untuk menggunakan layanan aplikasi
                    manajemen apotek yang disediakan Apotek Digital.
                    Setelah subscription/berlanggganan telah dibayar,
                    anda bisa membatalkan setiap saat tanpa biaya
                    tambahan kecuali yang sudah dibayarkan. Pembatalan
                    membutuhkan paling lama 31 hari sebelum efektif.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
      {renderSecondClosing()}
    </Fragment>
  );
};

export default TermsAndConditions;
