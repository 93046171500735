import Loadable from 'react-loadable';
import Loading from 'components/layout/Loader';

const NotFoundPage = Loadable({
  loader: () => import('./index').then((m) => m.NotFoundPage),
  loading: Loading,
});
const HomePage = Loadable({
  loader: () => import('./index').then((m) => m.HomePage),
  loading: Loading,
});
const LoginPage = Loadable({
  loader: () => import('./index').then((m) => m.LoginPage),
  loading: Loading,
});
const RegisterPage = Loadable({
  loader: () => import('./index').then((m) => m.RegisterPage),
  loading: Loading,
});
const ResetPasswordPage = Loadable({
  loader: () => import('./index').then((m) => m.ResetPasswordPage),
  loading: Loading,
});
const CreateApotekPage = Loadable({
  loader: () => import('./index').then((m) => m.CreateApotekPage),
  loading: Loading,
});
const ChooseApotekPage = Loadable({
  loader: () => import('./index').then((m) => m.ChooseApotekPage),
  loading: Loading,
});
const PrivacyPage = Loadable({
  loader: () => import('./index').then((m) => m.PrivacyPage),
  loading: Loading,
});
const TermsAndConditions = Loadable({
  loader: () => import('./index').then((m) => m.TermsAndConditions),
  loading: Loading,
});
const PricingPage = Loadable({
  loader: () => import('./index').then((m) => m.PricingPage),
  loading: Loading,
});
const FAQPage = Loadable({
  loader: () => import('./index').then((m) => m.FAQPage),
  loading: Loading,
});
const FeaturesPage = Loadable({
  loader: () => import('./index').then((m) => m.FeaturesPage),
  loading: Loading,
});

export {
  NotFoundPage,
  HomePage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  CreateApotekPage,
  ChooseApotekPage,
  PrivacyPage,
  TermsAndConditions,
  PricingPage,
  FAQPage,
  FeaturesPage,
};
