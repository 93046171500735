import {hot} from 'react-hot-loader/root';
import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {BrowserRouter, Route} from 'react-router-dom';
import Switch from 'react-router-transition-switch';

import Layout from 'layouts';

import NotFoundPage from 'pages/system/404';
import HomePage from 'pages/home';
import AboutPage from 'pages/about';
import PrivacyPage from 'pages/privacyPolicy';
import TermsAndConditions from 'pages/termsAndConditions';
import FAQPage from 'pages/faq';
import FeaturesPage from 'pages/features';

import {
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  CreateApotekPage,
  ChooseApotekPage,
} from 'pages/indexAsync';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

const routes = [
  {
    path: '/',
    Component: HomePage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/about',
    Component: AboutPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/privacy',
    Component: PrivacyPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/terms-and-conditions',
    Component: TermsAndConditions,
    exact: true,
    layout: 'main',
  },

  {
    path: '/faq',
    Component: FAQPage,
    exact: true,
    layout: 'main',
  },
  {
    path: '/features',
    Component: FeaturesPage,
    exact: true,
    layout: 'main',
  },

  {
    path: '/login',
    Component: LoginPage,
    exact: true,
  },
  {
    path: '/register',
    Component: RegisterPage,
    exact: true,
  },
  {
    path: '/reset-password',
    Component: ResetPasswordPage,
    exact: true,
  },
  {
    path: '/create-apotek',
    Component: CreateApotekPage,
    exact: true,
  },
  {
    path: '/choose-apotek',
    Component: ChooseApotekPage,
    exact: true,
  },
];

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Mulish', 'Arial'].join(','),
  },
  palette: {
    primary: {
      main: '#1166D8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#09A599',
      contrastText: '#fff',
    },
  },
  '.MuiIconButton-root': {
    outline: 'none',
  },
});

function Router({history = null}) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="id">
      <ThemeProvider theme={theme}>
        {/* <BrowserRouter> */}
        <ConnectedRouter history={history}>
          <Switch>
            {routes.map(({path, Component, exact, layout}) => (
              <Route path={path} key={path} exact={exact}>
                <Layout layout={layout}>
                  <Component layout={layout} />
                </Layout>
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
          {/* </BrowserRouter> */}
        </ConnectedRouter>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default hot(Router);
