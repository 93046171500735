import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <div style={{backgroundColor: '#0E70E011'}} className="py-6">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-12 order-md-1 order-1">
            <h5 className="">Tentang Apotek Digital</h5>
            <p className="" style={{opacity: 0.7}}>
              Apotek Digital, Sistem Informasi Manajemen apotek /
              Software Apotek cerdas berbasis cloud yang akan membuat
              kelola/monitor bisnis Apotekmu menjadi sangat mudah.
            </p>
            <div>
              <Link
                to={{
                  pathname:
                    'https://apotekdigital.com/playstore?r=web',
                }}
                style={{color: 'white'}}
                target="_blank">
                <img src="/google-play-badge.png" height={60} />
              </Link>
              <Link
                to={{
                  pathname:
                    'https://apotekdigital.com/appstore?r=web',
                }}
                style={{color: 'white'}}
                target="_blank">
                <img
                  src="/download-on-the-app-store.svg"
                  height={40}
                />
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-6 order-md-2 order-3">
            <h5 className="">Navigasi</h5>
            <div className="my-2">
              <Link className="" to="/">
                Home
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/features">
                Fitur
              </Link>
            </div>
            <div className="my-2">
              <Link
                to={{pathname: 'https://blog.apotekdigital.com'}}
                target="_blank">
                Blog
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/faq">
                FAQ
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/register">
                Daftar
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/register">
                Login
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/terms-and-conditions">
                Syarat dan Ketentuan
              </Link>
            </div>
            <div className="my-2">
              <Link className="" to="/privacy">
                Kebijakan Privasi
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-6 order-md-3 order-2">
            <h5 className="">Hubungi Kami</h5>
            <p className="" style={{opacity: 0.7}}>
              PT. Apotek Digital Indonesia <br />
              Gedung Arva Lt.3 Jl. Rp. Soeroso No. 40 <br />
              Kelurahan Gondangdia, Kecamatan Menteng <br />
              Jakarta Pusat
            </p>
            <p>
              <a href="mailto:info@apotekdigital.com">
                info@apotekdigital.com
              </a>
            </p>
            <p>
              <a href="https://wa.me/6285223932189">WhatsApp</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
