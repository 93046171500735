import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG,
);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth;
const firebaseAnalytics = firebaseApp.analytics();
firebaseAnalytics.setAnalyticsCollectionEnabled(true);
const databaseRef = firebaseApp
  .database(String(process.env.REACT_APP_FIREBASE_MAIN_DB))
  .ref();
const databaseDataRef = firebaseApp
  .database(String(process.env.REACT_APP_FIREBASE_DATA_DB))
  .ref();
const storageRef = firebaseApp.storage().ref();

const functionRef = firebaseApp.functions('asia-east2');
if (Boolean(process.env.REACT_APP_FIREBASE_FUNCTION_USE_EMULATOR)) {
  // firebase.database.enableLogging((message) => {
  //   console.log('[FIREBASE]', message);
  // });
  functionRef.useFunctionsEmulator('http://localhost:5001');
}
const serverTimestamp = firebase.database.ServerValue.TIMESTAMP;
export {
  firebaseAnalytics,
  firebaseApp,
  firebaseAuth,
  databaseRef,
  databaseDataRef,
  storageRef,
  functionRef,
  serverTimestamp,
};
