import React, {memo, useState} from 'react';
import useEventListener from 'helper/useEventListener';
import TopNav from 'components/layout/TopNav';
import Footer from 'components/layout/Footer';
import {
  createMuiTheme,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import TopBar from 'components/layout/TopBar';
import BottomBar from 'components/layout/BottomBar';

const MainLayout = memo(({children = ''}) => {
  // navbar mestinya di sini
  const [topBarWhite, setTopBarWhite] = useState(false);

  const theme = useTheme();
  useEventListener('scroll', () => {
    if (window.scrollY <= 0) {
      setTopBarWhite(false);
    } else {
      setTopBarWhite(true);
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <div style={{height: '100%'}}>
        {/* <TopNav isWhite={topBarWhite} /> */}
        <TopBar />
        {children}
        <Footer />
        <BottomBar />
      </div>
    </ThemeProvider>
  );
});

export default MainLayout;
