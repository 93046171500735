import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
  useScrollTrigger,
  useTheme,
  useMediaQuery,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import './style.css';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    // color: 'white',
  },
}));

function ElevationScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
  });
}

const MAIN_MENU = [
  {
    label: 'Pricing',
    url: '/pricing',
    icon: <InboxIcon />,
  },
  {
    label: 'blogs',
    url: '/blogs',
    icon: <InboxIcon />,
  },
  {
    label: 'FAQ',
    url: '/faq',
    icon: <InboxIcon />,
  },
];
export default function TopBar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [referer, setReferer] = useState('');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get('referer');
    if (ref) {
      setReferer(ref);
    }
  }, []);
  const list = () => (
    <div
      role="presentation"
      style={{width: 250}}
      onClick={() => setDrawerVisible(false)}
      onKeyDownk={() => setDrawerVisible(false)}>
      <List>
        <Link
          style={{
            padding: 0,
            marginRight: 10,
            textAlign: 'center',
            margin: 10,
          }}
          className="nav-link"
          to="/">
          <img
            src="https://res.cloudinary.com/apotekdigital/image/upload/v1576158424/web/logo_apotek_digital_ercyti.png"
            height="50"
          />
        </Link>
        {MAIN_MENU.map((menu, index) => (
          <ListItem button component="a" href={menu.url} key={index}>
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <ElevationScroll {...props}>
        <AppBar
          style={{
            backgroundColor:
              window.pageYOffset === 0 ? '#fff0' : 'white',
            borderColor: 'white',
            borderRadius: '0 0 20px 20px',
          }}
          position="fixed">
          <Container style={isMobile ? {padding: 0} : {}}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                <Link
                  style={{padding: 0, marginRight: 10}}
                  className="nav-link"
                  to="/">
                  <img
                    src="https://res.cloudinary.com/apotekdigital/image/upload/v1576158424/web/logo_apotek_digital_ercyti.png"
                    height="30"
                  />
                </Link>
              </Typography>

              <Button
                className="d-none d-sm-block"
                onClick={() => history.push('features')}
                color="black"
                style={{marginRight: 10}}>
                Fitur
              </Button>
              <Button
                className="d-none d-sm-block"
                href="https://blog.apotekdigital.com"
                target="_blank"
                color="black"
                style={{marginRight: 10}}>
                Blog
              </Button>
              <Button
                className="d-none d-sm-block"
                href="https://wiki.apotekdigital.com"
                target="_blank"
                color="black"
                style={{marginRight: 10}}>
                Panduan
              </Button>
              <Button
                className="d-none d-sm-block"
                onClick={() => history.push('faq')}
                color="black"
                style={{marginRight: 10}}>
                FAQ
              </Button>
              <Button
                variant="outlined"
                className="d-none d-sm-block"
                onClick={() => history.push('login')}
                color="black"
                style={{marginRight: 10}}>
                Login
              </Button>

              <Button
                variant="contained"
                onClick={() =>
                  history.push(
                    `/register${
                      referer ? '?referer=' + referer : ''
                    }`,
                  )
                }
                color="secondary"
                style={{fontWeight: 600}}>
                Daftar
              </Button>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <SwipeableDrawer
        anchor={'left'}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onOpen={() => setDrawerVisible(true)}>
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
