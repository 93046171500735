import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import DescriptionIcon from '@material-ui/icons/Description';
import ChatIcon from '@material-ui/icons/Chat';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  Badge,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#262213',
    // borderTop: 'grey solid 1px',
    position: 'fixed',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    fontSize: 8,
    width: '100%',
    overflow: 'hidden',
    '& .Mui-selected': {
      // color: '#F15722',
    },
    '& .MuiBottomNavigationAction-root': {
      padding: 0,
    },
  },
  drawerRoot: {
    '& .MuiDrawer-paperAnchorBottom': {
      borderRadius: '10px 10px 0 0',
    },
  },
});

export default function AppNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(-1);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === `/`) {
      setValue(0);
    } else if (location.pathname === `/features`) {
      setValue(1);
    } else if (location.pathname === `/faq`) {
      setValue(2);
    } else {
      setValue(3);
    }
  }, [location.pathname]);
  const [visible, setVisible] = useState(false);
  return (
    <Paper elevation={4} className="d-sm-none">
      <BottomNavigation
        value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        showLabels
        className={classes.root}>
        <BottomNavigationAction
          onClick={() => history.push('/')}
          label="Home"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          onClick={() => history.push('/features')}
          label="Fitur"
          icon={<SchoolIcon />}
        />
        {/* <BottomNavigationAction
          onClick={() => history.push('/blogs')}
          label="Blog"
          icon={
            <i
              className="far fa-newspaper"
              style={{fontSize: 22, margin: 2}}></i>
          }
        /> */}
        <BottomNavigationAction
          onClick={() => history.push('/faq')}
          label="FAQ"
          icon={<DescriptionIcon />}
        />
        <BottomNavigationAction
          onClick={() => {
            try {
              // eslint-disable-next-line no-undef
              const tawkApi = Tawk_API;

              if (tawkApi && _.isFunction(tawkApi.toggle))
                tawkApi.toggle();
            } catch (error) {
              console.error(error);
            }
          }}
          label="Chat"
          icon={
            <Badge variant="dot" color="error">
              <ChatIcon />
            </Badge>
          }
        />
        {/* <BottomNavigationAction
          onClick={() => history.push('/videos')}
          label="Video"
          icon={<VideoLibraryIcon />}
        /> */}
        {/* <BottomNavigationAction
          onClick={() => setVisible(true)}
          label=""
          icon={<MoreVertIcon />}
        /> */}
      </BottomNavigation>
    </Paper>
  );
}
