import React, {Fragment, useEffect, memo} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
// import NProgress from 'nprogress';
import {Helmet} from 'react-helmet';
import EmptyLayout from './Empty';
import MainLayout from './MainLayout';

// import {Spin} from 'antd';

const Layout = memo(({children, layout}) => {
  // katanya sih mirip pureComponent?
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const BootstrappedLayout = () => {
    if (layout === 'main') return <MainLayout>{children}</MainLayout>;
    else return <EmptyLayout>{children}</EmptyLayout>;
  };

  return (
    <Fragment>
      <Helmet
        titleTemplate="Apotek Digital | %s"
        title="Go Digital!"
      />
      {BootstrappedLayout()}
    </Fragment>
  );
  // }
});

export default Layout;
