import {functionUrl} from 'helper/functions';
import axios from 'axios';

export async function checkBlastCounter({code}) {
  try {
    let response = await axios.post(
      `${functionUrl}/public/check-blast-code`,
      {
        code,
      },
    );

    return {
      data: response.data,
      isSuccess: Boolean(
        response.status >= 200 && response.status < 300,
      ),
    };
  } catch (error) {
    throw error;
  }
}
